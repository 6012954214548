import React from 'react'

const AuthKeyCheck = (props) => {
  // console.log("AuthKeyCheck", props);
  if (props === 0) {
    window.location.href = "/login";
  }
  return (
    <>

    </>
  )
}

export { AuthKeyCheck }
