import React, { useState, useEffect } from 'react'
import SideMenu from './SideMenu'
import TopBand from './inc/TopBand'
import UpdateLead from './UpdateLead'
import axios from 'axios'
import CommonVariables from './CommonVariables'
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import $, { isEmptyObject } from 'jquery';
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { AuthKeyCheck } from './Helpers'
import AuthCheck from './AuthCheck'

const selectAllCheckboxes = () => {
  var check_state = (document.getElementById('customCheck1').checked);
  const checkboxes = document.querySelectorAll("input[name='chckbx']");
  checkboxes.forEach((cb) => { cb.checked = check_state; });
}

const UpLead = () => {
  const navigate = useNavigate();
  const usertype = useSelector(state => state.data.entities)
  const [token, setToken] = useState(usertype.session_key);
  useEffect(() => {
    setToken(usertype.session_key)
  }, [usertype])
  const [lid, setLid] = useState(0)
  const [lidsu, setLidSU] = useState(0)
  const [btnsubmit, setBtnsubmit] = useState("")
  const [selectedFile, setSelectedFile] = useState("")
  const [tblData, setTblData] = useState([])
  const [updateDTable, setUpdateDTable] = useState(0)
  const [agents, setAgents] = useState([])
  useEffect(() => {
    const fetchTableData = async () => {
      const fbody = new FormData();
      fbody.append("action", "user_list");
      fbody.append("user_type", 2);
      await axios({
        method: "POST",
        url: CommonVariables.ApiUrl,
        data: fbody,
        headers: { "X-API-KEY": "taranakisolar", 'Authorization': `Bearer ${token}` },
      })
        .then(function (apiresponse) {
          AuthCheck(apiresponse.data.urefid)
          setAgents(apiresponse.data.data)
          // console.log("LINE108");
        })
        .catch(function (apierror) {
          console.log(apierror);
        });
    };
    fetchTableData();
  }, []
  )
  const ResetLid = () => {
    setLid(0);
    setLidSU(0);
    //setUpdateDTable(updateDTable+1)
  }
  ///Edit Popup Function
  const ShowEditPopup = (props) => {
    if (props.lid) {
      return <UpdateLead
        lid={props.lid}
        compo={props.compo}
        ResetLid={ResetLid}
        agents={agents}
      />;
    }
    return;
  }
  const ClickEditPopup = (props) => {
    if (lid === props) {
      alert("You have recently closed this lead, Try again.")
      setLid(0)
      return
    }
    setLid(props)
  }
  ///Edit Status Update Popup Function
  const ShowStatusUpdatePopup = (props) => {
    console.log("ShowStatusUpdatePopup");
    if (props.lid) {
      return <UpdateLeadStatus
        lid={props.lid}
        compo={props.compo}
        ResetLid={ResetLid}
      />;
    }
    return;
  }
  const ClickStatusUpdatePopup = (props) => {
    if (lidsu === props) {
      alert("You have recently updated this lead status, Try again.")
      setLidSU(0)
      return
    }
    setLidSU(props)
  }
  useEffect(() => {
    if ($.fn.DataTable.isDataTable('#tblfreshleads')) {
      $('#tblfreshleads').DataTable().destroy();
    }
    $('#tblfreshleads tbody').empty();
    $('#tblfreshleads').DataTable({
      "searching": false,
      order: [],
      columnDefs: [{
        'targets': [0, 8], /* column index [0,1,2,3]*/
        'orderable': false, /* true or false */
      }],
      lengthMenu: [
        [100, 250, 500, 1000, 5000],
        [100, 250, 500, 1000, 5000],
      ],
      processing: true,
      serverSide: true,
      ajax: {
        url: CommonVariables.ApiUrl,
        type: "POST",
        data: function (d) {
          d.action = "lead_list";
          d.actiontype = "freshleads";
          d.cby = usertype.id;
        },
        beforeSend: function (request) {
          // Fix: Add a space after 'Bearer'
          request.setRequestHeader('Authorization', 'Bearer ' + token);
        }
      },
      success: function(apiresponse){
        AuthKeyCheck(apiresponse.urefid)
    },

      columns: [
        {
          "data": 'id',
          render: function (data, type, row) {
            return '<input name="chckbx" type="checkbox" className="custom-control-input" id=customCheck' + row['id'] + ' value=' + row['id'] + ' />';
          }
        },
        {
          data: 'refid',
          render: function (data, type, row) {
            return '<p class="prevent-select">' + row['refid'] + '</p>';
          }

        },
        {
          data: 'business_name',
          render: function (data, type, row) {
            return '<p class="prevent-select">' + row['business_name'] + '</p>';
          }
        },
        {
          data: 'address',
          render: function (data, type, row) {
            return '<p class="prevent-select">' + row['address'] + '</p>';
          }
        },
        {
          data: 'number',
          render: function (data, type, row) {
            return '<a class="prevent-select" href="tel:' + row['number'] + '">' + row['number'] + '</a>';
          }
        },
        {
          data: 'fttpsogea',
          render: function (data, type, row) {
              return '<p class="prevent-select">' + row['fttpsogea'] + '</p>'
          }
      },
        {
          data: 'bb_type',
          render: function (data, type, row) {
            return '<p class="prevent-select">' + row['bb_type'] + '</p>';
          }
        },
        /*{
          "data": 'draw',
          render: function (data, type, row) {
            return '<button data-id="' + row['id'] + '" type="button" class="btntdtr_'+row['status_txt']+row['remove_green']+' btnstatus btn btn-' + row['status_color'] + '">' + row['status_txt'] + '</button>';
          }
        },*/
        {
          "data": 'status_txt',
          "render": function (data, t, row) {
            var $select = $("<select  data-id='" + row['id'] + "'  class='btntdtr_" + row['status_txt'] + row['remove_green'] + " status_select btn btn-" + row['status_color'] + "'></select>", {
              "id": row[0] + "start",
              "value": data
            });
            $.each(CommonVariables.lead_status, function (k, v) {
              var $option = $("<option></option>", {
                "text": v.text,
                "value": v.value
              });
              if (data === v.text) {
                $option.attr("selected", "selected")
                $select.children('option[value="0"]').remove();
              }
              $select.append($option);
            });


            return $select.prop("outerHTML");
          }
        },
        {
          "data": 'draw',
          render: function (data, type, row) {
            return '<button data-id="' + row['id'] + '" type="button" class="btnnotes btn btn-secondary">Enter</button>';
          }
        }
      ]
    });
    $('#tblfreshleads tbody').on('click', '.btnnotes', function () {
      var clid = $(this).attr('data-id')
      ClickEditPopup(clid)
    });
    $('#tblfreshleads tbody').on('change', 'select', function () {
      $(this).closest('tr').css("background-color", "#04bc70");
      var clid = $(this).attr('data-id')
      var dataval = $(this).val()
      var prams = { "clid": clid, "dataval": dataval }
      UpdateLeadStatus(prams)
    });
    $('#tblfreshleads tbody').on('click', 'button', function () {
      $(this).closest('tr').css("background-color", "#04bc70");
    });
  }, [updateDTable])

  if (usertype.user_type != 2) {
    navigate("/");
  }
  const resetPage = (event) => {
    console.log("resetPage")
    setUpdateDTable(updateDTable + 1)
    //window.location.reload();
  }
  //MOve to Lead Pot
  const RemoveGreen = async (props) => {
    let lead_vals = $("input[name=chckbx]:checked").map(function () {
      return this.value;
    }).get().join(",");
    if (isEmptyObject(lead_vals)) {
      alert("Please select a lead to remove green.")
      return;
    }
    //Moving leads
    const fbody = new FormData();
    fbody.append("lead_ids", lead_vals);
    fbody.append("actiontype", "removegreen");
    fbody.append("action", "lead_bulk_update");
    await axios({
      method: "POST",
      url: CommonVariables.ApiUrl,
      data: fbody,
      headers: { 'Authorization': `Bearer ${token}` }
    })
      .then(function (apiresponse) {
        AuthKeyCheck(apiresponse.data.urefid)
        setUpdateDTable(updateDTable + 1)
        alert(apiresponse.data.resmsg)
        //window.location.reload();
      })
      .catch(function (apierror) {
        console.log(apierror)
      });
  }
  const UpdateLeadStatus = (props) => {
    const updateData = async (props) => {
      const fbody = new FormData();
      fbody.append("lead_id", props.clid);
      fbody.append("cstatus", props.dataval);
      fbody.append("action", "lead_update");
      await axios({
        method: "POST",
        url: CommonVariables.ApiUrl,
        data: fbody,
        headers: { 'Authorization': `Bearer ${token}` }
      })
        .then(function (apiresponse) {
          AuthKeyCheck(apiresponse.data.urefid)
        })
        .catch(function (apierror) {
          console.log(apierror)
        });
    }
    updateData(props)
  }
  return (
    <React.Fragment>
      <div className="container-fluid p-0" style={{ overflow: 'hidden' }}>
        <SideMenu UpLead="active" />
        <div className="body_">
          <TopBand
            pname="Leads"
          />
          <div className="leads">
            <div className="darkbg">
              <div className="bg-white padding10">
                <div className="button-header">
                  <button onClick={e => resetPage(e)} type="button" className="btn btn-success"><i className="fa fa-refresh"></i></button>
                  <button onClick={e => RemoveGreen()} type="button" className="btn btn-warning">Remove Green</button>


                </div>
                <ShowEditPopup
                  lid={lid}
                  compo='/uplead'
                />

                <ShowStatusUpdatePopup
                  lid={lidsu}
                  compo='/uplead'
                />
                <div className="table-responsive">
                  <table id="tblfreshleads" className="table" style={{ width: "100%" }}>
                    <thead>
                      <tr>
                        <th> <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox" className="custom-control-input" id="customCheck1"
                            onClick={selectAllCheckboxes}
                          />
                          <label className="custom-control-label" htmlFor="customCheck1"></label>
                        </div></th>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Address</th>
                        <th>Number</th>
                        <th>SOGEA/FTTP</th>
                        <th>BB Type</th>
                        <th>Status</th>
                        <th>Notes</th>
                      </tr>
                    </thead>
                    <tbody>

                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment >
  )
}
export default UpLead
