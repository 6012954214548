import React from 'react'
import { Link } from 'react-router-dom'

const Login1=()=> {
  return (
    <div className="bg-color login">
    <div className="container">
      <div className="row">
        <div className="col-md-4"></div>
        <div className="col-md-4">
          <div className="box bg-white margintop_226"> <img src="assets/img/logo.png" /><br />
            <Link to="/login" className="btn btn-success">Log in</Link> </div>
        </div>
        <div className="col-md-4"></div>
      </div>
    </div>
  </div>
  )
}
export default Login1
