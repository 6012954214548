import { createAsyncThunk, createSlice } from "@reduxjs/toolkit/";
//Get Loggedin User Data
function getsessionStorageValue(key, defaultValue) {
    // getting stored value
    if (typeof window != "undefined") {
        const saved = sessionStorage.getItem(key);
        const initial = saved != null ? JSON.parse(saved) : defaultValue;
        return initial;
    }
}
const userdata = getsessionStorageValue("userdata", "defaultValue");
const initialState = {
    value: 2,
    data: 1,
    entities: userdata
}
export const fetchUserData = createAsyncThunk(
    'userdata',
    async () => {
        let res = await fetch('https://jsonplaceholder.typicode.com/users');
        let res2 = await res.json()
        return (res2[Math.floor(Math.random() * 10)])
    }
)
export const commonSlice = createSlice({
    name: "counter",
    initialState,
    reducers: {
        increment: (state) => {
            state.value += 1;
        },
        decrement: (state) => {
            state.value -= 1;
        },
        setval: (state, action) => {
            //console.log(action);
            state.data = action.payload
        },
        setudata: (state, action) => {
            //console.log(action);
            state.entities = action.payload
        }
    },
    extraReducers: {
        [fetchUserData.fulfilled]: (state, action) => {
            console.log(action.payload);
            state.entities = (action.payload)
            //console.log(state.entities);
        },
        [fetchUserData.pending]: (state, action) => {
            state.entities = "Loading"
        },
        [fetchUserData.rejected]: (state, action) => {
            state.entities = "Retry"
        }
    }
});
export const { increment, decrement, setval, setudata } = commonSlice.actions;
export default commonSlice.reducer;