import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Home from './Home'
import UserHome from './Up-Home'
import FreshLeads from './FreshLeads'
import DncLeads from './DncLeads'
import Deals from './Deals'
import UsedLeads from './UsedLeads'
import LeadswithAgents from './LeadswithAgents'
import LeadPot from './LeadPot'
import AgentPipeline from './AgentPipeline'
import Login from './Login'
import LogOut from './LogOut'
import Login1 from './Login1'
import Team from './Team'
import Import from './Import'
import CustomerLead from './Up_CustomerLead'
import UpdateLead from './UpdateLead'
import Up_pipeline from './Up_Pipeline'
import UpCallBack from './Up_CallBack'
import AdminLeads from './AdminLeads'
import UpLead from './Up_Lead'
import UpdateUser from './UpdateUser'
import ImportLog from './ImportLog'
import AdminImportLog from './AdminImportLog'
import Verify from './Verify'
import AllLeadsStatus from './AllLeadsStatus'


export default function RouterPage() {
    return (
        <div>
            <Router>
                <Routes>
                    <Route path="/login" element={<Login />} />
                    <Route path="/login1" element={<Login1 />} />
                    <Route path="/logout" element={<LogOut />} />
                    <Route path="/" exact element={<Home />} />
                    <Route path="/user" exact element={<UserHome />} /> 
                    <Route path="/adminleads" element={<AdminLeads />} />
                    <Route path="/adminleads/:lid" element={<AdminLeads />} />
                    <Route path="/freshleads" element={<FreshLeads />} />
                    <Route path="/freshleads/:lid" element={<FreshLeads />} />
                    <Route path="/importlog" element={<ImportLog />} />
                    <Route path="/adminimportlog" element={<AdminImportLog />} />
                    <Route path="/deals" element={<Deals />} />
                    <Route path="/deals/:lid" element={<Deals />} />
                    <Route path="/usedleads" element={<UsedLeads />} />
                    <Route path="/usedleads/:lid" element={<UsedLeads />} />
                    <Route path="/leadswithagents" element={<LeadswithAgents />} />
                    <Route path="/leadpot" element={<LeadPot />} />
                    <Route path="/agentpipeline" element={<AgentPipeline />} />
                    <Route path="/Up_Pipeline" element={<Up_pipeline />} /> 
                    <Route path="/UpCallBack" element={<UpCallBack />} /> 
                    <Route path="/team" element={<Team />} />
                    <Route path="/import" element={<Import />} />
                    <Route path="/Up_Customerlead" element={<CustomerLead />} />
                    <Route path="/uplead" element={<UpLead />} /> 
                    <Route path='/updatelead' element={<UpdateLead />} />
                    <Route path='/updateuser' element={<UpdateUser />} />
                    <Route path='/verify' element={<Verify />} />
                    <Route path='/dncleads' element={<DncLeads />} />
                    <Route path='/allleadsstatus' element={<AllLeadsStatus />} />
                </Routes>
            </Router>
        </div>
    )
}
