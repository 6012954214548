import React, { useState, useEffect } from "react";
import axios from "axios";
import CommonVariables from "../CommonVariables";
import { isEmptyObject } from "jquery";
import jQuery from "jquery";
import { useSelector } from "react-redux";
import { AuthKeyCheck } from "../Helpers";
const selectAllCheckboxes = () => {
  var check_state = document.getElementById("customCheck2").checked;
  const checkboxes = document.querySelectorAll("input[name='chckbx2']");
  checkboxes.forEach((cb) => {
    cb.checked = check_state;
  });
};
const AssignLeads = (props) => {
  const userdata = useSelector(state => state.data.entities)
  const [token, setToken] = useState(userdata.session_key);
  useEffect(() => {
      setToken(userdata.session_key)
  }, [userdata])
  const [uid, setUid] = useState(2);
  const [leadCount, setLeadCount] = useState(0)
  const [hideinput, setHideinput] = useState(0)
  const [updateDTable, setUpdateDTable] = useState(0);
  const [tblData, setTblData] = useState([]);
  useEffect(() => {
    if (props.hideinput) {
      setHideinput(props.hideinput)
    }
  }, [props.hideinput]);
  const AssingLeadsToAgent = async (props) => {
    let agent_ids = jQuery("input[name=chckbx2]:checked")
      .map(function () {
        return this.value;
      })
      .get()
      .join(",");
    if (isEmptyObject(agent_ids)) {
      if (hideinput) {
        alert("Please select any agent to filter leads.");
      } else {
        alert("Please select any agent to assign leads.");
      }
      return;
    }
    if (hideinput) {
      props.ResetToAgent(agent_ids);
      return
    }
    if (leadCount === 0) {
      alert("Please enter lead transfer QTY.");
      return;
    }
    //Moving leads
    const fbody = new FormData();
    fbody.append("action", "assign_to_agents");
    fbody.append("lead_counts", leadCount);
    fbody.append("agent_ids", agent_ids);
    if(props.page) {
      fbody.append("subaction", props.page);
    }
    await axios({
      method: "POST",
      url: CommonVariables.ApiUrl,
      data: fbody,
      headers: {'Authorization': `Bearer ${token}` },
    })
      .then(function (apiresponse) {
        console.log(apiresponse);
        setUpdateDTable(updateDTable + 1);
        alert(apiresponse.data.resmsg);
        window.location.reload();
      })
      .catch(function (apierror) {
        console.log(apierror);
      });
  };
  //user list : user_type 2
  useEffect(() => {
    const fetchTableData = async () => {
      const fbody = new FormData();
      fbody.append("action", "user_list");
      fbody.append("user_type", 2);
      await axios({
        method: "POST",
        url: CommonVariables.ApiUrl,
        data: fbody,
        headers: {'Authorization': `Bearer ${token}` },
      })
        .then(function (apiresponse) {
          AuthKeyCheck(apiresponse.data.urefid)
          setTblData(apiresponse.data.data);
          // console.log("LINE108");
        })
        .catch(function (apierror) {
          console.log(apierror);
        });
    };
    fetchTableData();
  }, [updateDTable]);
  return (
    <>
      <div
        className={"modal fade " + (uid ? "show d-block" : "")}
        id="exampleModalCenter"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog width0 modal-dialog-centered"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={(e) => {
                  setUid(0);
                  props.ResetToAgent();
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div id="tbl2">
              <div className="table-responsive" id="tablepopup">
                <table id="tbl" className="table" style={{ width: "80%" }}>
                  <thead>
                    <tr>
                      <th>
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="customCheck2"
                            onClick={selectAllCheckboxes}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="customCheck2"
                          ></label>
                        </div>
                      </th>
                      <th>Name</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tblData.map((result) => {
                      return (
                        <tr key={result.id}>
                          <td className="" width={"10px"}>
                            <div className="custom-control custom-checkbox">
                              <input
                                name="chckbx2"
                                type="checkbox"
                                className="custom-control-input"
                                id={`customCheck${result.id}`}
                                value={result.id}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor={`customCheck${result.id}`}
                              ></label>
                            </div>
                          </td>
                          <td className="text-start">{result.name}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <form className="form-inline">
                <div className="form-group mx-sm-3 mb-2">
                  <label for="inputPassword2" className="sr-only">Password</label>
                  <input
                    maxLength={5}
                    onChange={(e) => setLeadCount(e.target.value)}
                    type="text"
                    className={`form-control  ${hideinput > 0 ? "d-none" : ""}`}
                    id="inputPassword2" placeholder="1000"
                  />
                </div>
                <button
                  type="button"
                  onClick={(e) => AssingLeadsToAgent(props)}
                  className="btn btn-primary mb-2">{hideinput > 0 ? "Select" : "Assign to"} Agents</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AssignLeads;
