import React, { useEffect, useState } from 'react'
import TopBand from './inc/TopBand'
import SideMenu from './SideMenu'
import CommonVariables from './CommonVariables'
import axios from 'axios'
//For Redux
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from "react-router-dom"
import { AuthKeyCheck } from './Helpers'
const Home = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const usertype = useSelector(state => state.data.entities)
    
    const [token, setToken] = useState(usertype.session_key);
    useEffect(() => {
        setToken(usertype.session_key)
    }, [usertype])
    if (usertype.user_type != 1) {
        navigate("/user");
    }
    const [overview, setOverview] = useState([''])
    const [tbldata, setTblData] = useState([{ "name": "Harish", "pipeline": 1, "lead": 1 }, { "name": "Harish", "pipeline": 1, "lead": 1 }]);
    const [DealOverview, setDealOverview] = useState([""]);
    const [tpsdata, setTpsdata] = useState([])
    const [tpsday, Settpsday] = useState(0)
    useEffect(() => {
        const fetchTableData = async () => {
            const fbody = new FormData();
            fbody.append("action", "dashboard");
            await axios({
                method: "POST",
                url: CommonVariables.ApiUrl,
                data: fbody,
                headers: {'Authorization': `Bearer ${token}` }
            })
                .then(function (apiresponse) {
                    AuthKeyCheck(apiresponse.data.urefid)
                    if (apiresponse.data.rescode !=0) {
                        setOverview(apiresponse.data.data)
                        Settpsday(apiresponse.data.tpsdays)
                    if (apiresponse.data.tpsdata) {
                        setTpsdata(apiresponse.data.tpsdata)
                    }
                    if (apiresponse.data.data.dealoverview) {
                        setDealOverview(apiresponse.data.data.dealoverview)
                    }
                }
                })
                .catch(function (apierror) {
                    console.log(apierror)
                });
        }
        fetchTableData()
        const fetchAgentsLeadData = async () => {
            const fbody = new FormData();
            fbody.append("action", "leads_stats");
            await axios({
                method: "POST",
                url: CommonVariables.ApiUrl,
                data: fbody,
                headers: { 'Authorization': `Bearer ${token}` }
            })
                .then(function (apiresponse) {
                    AuthKeyCheck(apiresponse.data.urefid)
                    if (apiresponse.data.rescode !=0) {
                    setTblData(apiresponse.data.data)
                    }
                })
                .catch(function (apierror) {
                    console.log(apierror)
                });
        }
        fetchAgentsLeadData()
    }, [])
    const StartTPS = async (props) => {
        if (window.confirm("Please press OK to start TPS check.") == false) {
            return
        }
        //Moving leads
        const fbody = new FormData();
        if (props) {
            fbody.append("subaction", "tps_schedule_forced");
        } else {
            fbody.append("subaction", "tps_schedule");
        }
        //tps_schedule_forced
        fbody.append("action", "tps_checker");
        await axios({
            method: "POST",
            url: CommonVariables.ApiUrl,
            data: fbody,
            headers: { 'Authorization': `Bearer ${token}` }
        })
            .then(function (apiresponse) {
                AuthKeyCheck(apiresponse.data.urefid)
                alert(apiresponse.data.resmsg);
                if (apiresponse.data.rescode == "2") {
                    if (window.confirm("Press OK to REST current TPS check and start fresh TPS check.") == true) {
                        StartTPS("_forced")
                    }
                }
                if (apiresponse.data.rescode == "1") {
                    window.location.reload();
                }
            })
            .catch(function (apierror) {
                console.log(apierror)
            });
    }
    // return
    return (
        <React.Fragment>
            <div className="container-fluid p-0" style={{ overflow: 'hidden' }}>
                <SideMenu home="active" />
                <div className="body_">
                    <TopBand
                        pname="Dashboard"
                    />
                    <div className="section bg-light dash-box">
                        <h5 className="mt-3 mb-3 prevent-select">Leads Overview - Main {usertype.user_type}</h5>
                        <div className="row">
                            <div className="col-xl-3 col-sm-6">
                                <div className="das-box box-height color1">
                                    <h5 className='prevent-select'>Deals to date</h5>
                                    <div className="text-border"></div>
                                    <h1 className="mb-0 prevent-select">{overview.totalleads}</h1>
                                    <h5 className="mt-3 prevent-select">Commission written</h5>
                                    <div className="text-border"></div>
                                    <h1 className="mb-0 prevent-select">£{overview.commwritten}</h1>
                                </div>
                            </div>
                            <div className="col-xl-3 col-sm-6">
                                <div className="das-box box-height color">
                                    <h5 className='prevent-select'>Fresh Leads</h5>
                                    <div className="text-border"></div>
                                    <h1 className="mb-0 prevent-select">{overview.freshleads}</h1>
                                    <h5 className='prevent-select'>Leads to Date</h5>
                                    <div className="text-border"></div>
                                    <h1 className="mb-0 prevent-select">{overview.leadstodate}</h1>
                                </div>
                            </div>
                            <div className="col-xl-3 col-sm-6">
                                <div className="das-box box-height color2">
                                    <h5 className='prevent-select'>Used Leads</h5>
                                    <div className="text-border"></div>
                                    <h1 className="mb-0 prevent-select">{overview.usedleads}</h1>
                                </div>
                            </div>
                            <div className="col-xl-3 col-sm-6">
                                <div className="das-box box-height color3">
                                    <h5 className='prevent-select'>To be TPS'd</h5>
                                    <div className="text-border"></div>
                                    <h1 className="mb-0 prevent-select">{tpsdata.num_pending}</h1>
                                    {/* {overview.tpsdleads} */}
                                </div>
                            </div>
                        </div>
                        <div className="mt-4">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="das-box section1 color">
                                        <table className="table mb-0">
                                            <thead>
                                                <tr>
                                                    <th scope="row" className='prevent-select'>Agent Name</th>
                                                    <th className='prevent-select'>Pipeline</th>
                                                    <th className='prevent-select'>Leads</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {tbldata.map((result) => {
                                                    return (
                                                        <tr key={result.id}>
                                                            <td className='prevent-select'>{result.name}</td>
                                                            <td className='prevent-select'>{result.pipeline}</td>
                                                            <td className='prevent-select'>{result.lead}</td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="das-box color3">
                                        <h5 className='prevent-select'>TPS Notification:</h5>
                                        <h1 className="mt_20 prevent-select" >{tpsday} days <br />
                                            Remaining</h1>
                                        <h4>{tpsdata.num_pending} of {tpsdata.num_processed} number processed.</h4>
                                        <button onClick={e => StartTPS()} type="button" className="btn btn-warning">Start TPS</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mt-4">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="das-box section color">
                                        <h5 className='prevent-select'>Deal Overview <a href="/deals">View more</a></h5>
                                        <p className='prevent-select'>last 3 months</p>
                                        <table className="table w-50 mb-0 table-font">
                                            <tbody>
                                                {DealOverview.map((result, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <th scope="row" className='prevent-select'>{result.month}</th>
                                                            <td className='prevent-select'>{result.record}</td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment >
    )
}
export default Home;