import React,{useEffect, useState} from 'react'
import { Link } from 'react-router-dom'
import AuthCheck from './AuthCheck';
const SideMenu = (props) => {
  function getsessionStorageValue(key, defaultValue) {
    // getting stored value
    if (typeof window != "undefined") {
        const saved = sessionStorage.getItem(key);
        const initial = saved != null ? JSON.parse(saved) : defaultValue;
        return initial;
    }
}
const userdata = getsessionStorageValue("userdata", "defaultValue");
const [user_type, setUser_type] = useState(userdata.user_type);
  return (
    <div className="sliderbarfix">
      <AuthCheck />
      <div className={`bg-color text-center login sidebar  ${user_type === "1" ? "" : "d-none"}`}> <img src="/assets/img/white-logo.png" />
        <ul>
          <li className={props.home}><Link to="/"> Dashboard</Link></li>
          <li className={props.freshleads}><Link to="/freshleads">Fresh Leads</Link></li>
          <li className={props.deals}><Link to="/deals">Deals</Link></li>
          <li className={props.usedleads}><Link to="/usedleads">Used Leads</Link></li>
          <li className={props.leadswithagents}><Link to="/leadswithagents">Leads with Agents</Link></li>
          <li className={props.leadpot}><Link to="/leadpot">Lead pot</Link></li>
          <li className={props.agentpipeline}><Link to="/agentpipeline">Agent Pipeline</Link></li>
          <li className={props.UpCallBack}><Link to="/UpCallBack"> Call Back</Link>  <span className={`badge badge-danger  ${userdata.duealert > 0 ? "" : "d-none"}`}>{userdata.duealert}</span></li>
          <li className={props.dncleads}><Link to="/dncleads">DNC Leads</Link></li>
          <li className={props.allleadsstatus}><Link to="/allleadsstatus">All Leads</Link></li>
          <li className={props.adminleads}><Link to="/adminleads">Admin Leads</Link></li>
        </ul>
        <div className="sidefooter"><Link to="/logout" className="btn btn-primary">Log out</Link></div>
      </div>
      <div className={`bg-color text-center login sidebar  ${user_type === "2" ? "" : "d-none"}`}> <img src="/assets/img/white-logo.png" />
        <ul>
          <li className={props.home}><Link to="/user"> Dashboard</Link></li>
          <li className={props.UpLead}><Link to="/uplead">My Leads</Link></li>
          <li className={props.UpPipeline}><Link to="/Up_Pipeline"> Pipeline</Link></li>
          <li className={props.UpCallBack}><Link to="/UpCallBack"> Call Back</Link></li>
          {/* <li className={props.dncleads}><Link to="/dncleads">DNC Leads</Link></li> */}
          <li className={props.formstack}><a target={"_blank"} href="https://inspiretelecom.formstack.com/forms/inspiretelecomdeal"><button type="button" className="btn btn-success border-radius-20 mt-5">Complete Deal Sheet</button></a></li>
          <li className={props.formstack}><a target={"_blank"} href="https://inspiretelecom.formstack.com/forms/emailform"><button type="button" className="btn btn-pink border-radius-20">Email Form</button></a></li>
        </ul>
        <div className="sidefooter"><Link to="/logout" className="btn btn-primary">Log out</Link></div>
      </div>
    </div>
  )
}
export default SideMenu;