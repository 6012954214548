import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import CommonVariables from './CommonVariables';
import "datatables.net-dt/js/dataTables.dataTables";
import $ from 'jquery';
import { useSelector } from 'react-redux';
import LogListDetail from './LogListDetail';
import { AuthKeyCheck } from './Helpers';
import axios from 'axios';
import Swal from "sweetalert2";
const LogList = (props) => {
    const [tblData, setTblData] = useState()
    const [lidNumber, setLidNumber] = useState(props.lnumber);
    const [lid, setLid] = useState(0);
    const [lidData, setLidData] = useState([])
    const [searchValue, setSearchValue] = useState("");
    const userdata = useSelector(state => state.data.entities)
    const [token, setToken] = useState(userdata.session_key);

    useEffect(() => {
        const fetchTableData = async () => {
            const fbody = new FormData();
            fbody.append("action", "call_log_detail");
            fbody.append("id", lid);
            await axios({
                method: "POST",
                url: CommonVariables.ApiUrl,
                data: fbody,
                headers: {
                    'Authorization': `Bearer ${token}`
                },
            })
                .then(function (apiresponse) {
                    AuthKeyCheck(apiresponse.data.urefid)
                    if (apiresponse.data.rescode === 1) {
                        console.log(apiresponse.data.data);
                        const res_array = [];
                        for (let i in apiresponse.data.data) {
                            res_array.push([i, apiresponse.data.data[i]]);
                        };
                        // console.log(res_array);
                        setLidData(res_array);
                    }
                })
                .catch(function (apierror) {
                    console.log(apierror);
                });
        };
        if (lid && token) {
            fetchTableData();
        }
    }, [lid]);
    console.log("Rdata", tblData);
    useEffect(() => {
        if (lid > 0) {
            console.log("lassi", lid);
            return
        }
        else {
            console.log("lassi-12", lid);
            $('#loglead').DataTable({
                "searching": false,
                search: {
                    "search": searchValue
                },
                order: [],
                columnDefs: [{
                    targets: '_all',
                    // 'targets': [0, 7, 8], /* column index [0,1,2,3]*/
                    'orderable': false, /* true or false */
                }],
                lengthMenu: [
                    [100, 250, 500, 1000, 5000],
                    [100, 250, 500, 1000, 5000],
                ],
                processing: true,
                serverSide: true,
                ajax: {
                    url: CommonVariables.ApiUrl,
                    type: "POST",
                    data: function (d) {
                        d.action = "call_log_list";
                        d.number = lidNumber;
                        var searchValue = d.search.value;
                        setSearchValue(searchValue);
                    },
                    beforeSend: function (request) {
                        // Fix: Add a space after 'Bearer'
                        request.setRequestHeader('Authorization', 'Bearer ' + token);
                    }
                },
                success: function (apiresponse) {
                    AuthKeyCheck(apiresponse.urefid)
                },

                columns: [
                    {
                        data: 'cby_label',
                    },
                    {
                        data: 'cdate',
                    },
                    {
                        data: 'id',
                        render: function (data, type, row) {
                            return `<span data-toggle="modal" data-target="#modal_log_detail" data-backdrop="static" data-keyboard="false"  class="cursor-pointer" data-id= ${row['id']} title="Show Log"><i class="fa fa-eye"></i></span>`
                        }
                    },
                ]
            });
            $('#loglead tbody').on('click', 'span', function () {
                var clid = $(this).attr('data-id')
                setLid(clid)
            });
        }
    }, [token, lid])
    useEffect(() => {
        setToken(userdata.session_key)
    }, [userdata])
    return (
        <>
            {/* <ShowLogPopup id={lid} /> */}
            <div className={"modal fade " + (lidNumber > 0 ? 'show d-block' : '')} id="modal_detail">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-body" style={{ height: '100vh', overflow: 'auto' }}>
                            <div className="form-row">
                                <div className="container-fluid p-0" style={{ overflow: 'hidden' }}>
                                    <div className="p-0 leads coustomerlead">
                                        <div className="row">
                                            <div className="col-xl-11 col-11">
                                                <h4 className="mb-0 prevent-select">Log Actions for {lidNumber} {lid ? " - "+lid:""}
                                                </h4>
                                            </div>
                                            {lid === 0 ? <div className="col-xl-1 col-1 cursor-pointer" onClick={e => {
                                                setLidNumber(0)
                                                props.ResetLogPopup()
                                            }}>
                                                Close
                                            </div> : <div className="col-xl-1 col-1 cursor-pointer" onClick={e => {
                                                setLid(0)
                                            }}>
                                                Back
                                            </div>}

                                        </div>

                                        <div className={"bg-white padding10 p-0 mt-3"}>

                                            {lid === 0 ?
                                                <div className="table-responsive">
                                                    <table className={"table table-striped table-hover "} id="loglead">
                                                        <thead>
                                                            <tr>
                                                                <th>Updated By</th>
                                                                <th>Updated On</th>
                                                                <th>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                        </tbody>
                                                    </table></div> :
                                                <table className={"table table-striped table-hover "}>
                                                    <thead>
                                                        <tr>
                                                            <th>Action Key</th>
                                                            <th>Action Value</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {lidData.map((result) => {
                                                            return (
                                                                <tr>
                                                                    <td>{result['0'].replace(/_/g, ' ')}</td>
                                                                    <td>{result['1']}</td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </table>}

                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div></>
    )
}
export default LogList
