import React, { useState, useEffect } from 'react'
import axios from 'axios'
import CommonVariables from './CommonVariables'
import { isEmptyObject } from 'jquery'
import { useSelector } from 'react-redux'
import { AuthKeyCheck } from './Helpers'
const UpdateUser = (props) => {
  const userdata = useSelector(state => state.data.entities)
  const [token, setToken] = useState(userdata.session_key);
  useEffect(() => {
    setToken(userdata.session_key)
  }, [userdata])
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [password, setPassword] = useState("")
  const [user_type, setUser_type] = useState()
  const [uid, setUid] = useState(props.uid)
  const [TblData, setTblData] = useState("")

  const DeleteUser = (e) => {
    e.preventDefault();
    let text;
    if (window.confirm("Press OK to delete this user!") == true) {
      //Delete User
      const fbody = new FormData();
      fbody.append("user_id", uid);
      fbody.append("action", "deleteuser");
      axios({
        method: "POST",
        url: CommonVariables.ApiUrl,
        data: fbody,
        headers: { 'Authorization': `Bearer ${token}` }
      })
        .then(function (apiresponse) {
          AuthKeyCheck(apiresponse.data.urefid)
          alert(apiresponse.data.resmsg)
          window.location.reload();
        })
        .catch(function (apierror) {
          console.log(apierror)
        });
    } else {
      return
    }
  }

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (isEmptyObject(name)) {
      alert("Name is mandatory.");
      return;
    }
    if (isEmptyObject(email)) {
      alert("Email is mandatory.");
      return;
    }
    if (isEmptyObject(phone)) {
      alert("Phone is mandatory.");
      return;
    }
    if (isEmptyObject(user_type)) {
      alert("Access level is mandatory.");
      return;
    }


    let addleadformdata = document.getElementById("teamform")
    let formData = new FormData(addleadformdata);
    //user_id
    formData.append('action', uid > 1 ? "user_update" : 'user_add')
    formData.append('name', name)
    formData.append('email', email)
    formData.append('phone', phone)
    formData.append('user_type', user_type)
    formData.append('user_id', uid)
    if (password) {
      formData.append('password', password)
    }

    axios({
      method: 'post',
      url: CommonVariables.ApiUrl,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${token}` }
    })
      .then(function (response) {
        AuthKeyCheck(response.data.urefid)
        console.log(response)
        if (response.data.rescode === 1) {
          setUid(0)
          props.setupdateDTable()
          alert(response.data.resmsg);
        } else {
          alert(response.data.resmsg);
        }
      })
      .catch(function (response) {
        //handle error
        console.log(response)
      });
  }
  useEffect(() => {
    if (uid > 1) {
      const fetchTableData = async () => {
        const fbody = new FormData();
        fbody.append("action", "user_detail");
        fbody.append("user_id", uid);
        await axios({
          method: "POST",
          url: CommonVariables.ApiUrl,
          data: fbody,
          headers: { 'Authorization': `Bearer ${token}` }
        })
          .then(function (apiresponse) {
            AuthKeyCheck(apiresponse.data.urefid)
            setTblData(apiresponse.data.data)
            setName(apiresponse.data.data.name)
            setEmail(apiresponse.data.data.email)
            setPhone(apiresponse.data.data.phone)
            setUser_type(apiresponse.data.data.user_type)
            // console.log("LINE108");
          })
          .catch(function (apierror) {
            console.log(apierror)
          });
      }
      fetchTableData()
    }
  }, [uid])
  return (
    <>

      <div
        className=
        {"modal fade " + (uid ? 'show d-block' : '')}
        id="exampleModalCenter"

        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog width0 modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className={`modal-header  ${uid > 1 ? "d-none" : ""}`}>
              <h5 className="modal-title" id="exampleModalLongTitle">
                Invite a team member
              </h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={e => setUid(0)}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p className={`font14  ${uid > 1 ? "d-none" : ""}`}>
                Enter the email address of the team member you'd like to invite
                and assign an access level. We will email them a link to set their
                password.
              </p>
              <form id="teamform">
                <div className="form-group">
                  <label htmlFor="name" className="font-weight-600 font14">
                    Name <span className="text-danger">*</span>
                  </label>
                  <input
                    type="name"
                    className="form-control"
                    id=''
                    aria-describedby="name"
                    placeholder=""
                    required=""
                    defaultValue={name}
                    onChange={e => setName(e.target.value)}

                  />
                </div>
                <div className="form-group">
                  <label htmlFor="email" className="font-weight-600 font14">
                    Email <span className="text-danger">*</span>
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"

                    aria-describedby="email"
                    placeholder=""
                    required=""
                    defaultValue={email}
                    onChange={e => setEmail(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="phone" className="font-weight-600 font14">
                    Phone No. <span className="text-danger">*</span>
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="phone"
                    aria-describedby="phone"
                    placeholder=""
                    required=""
                    defaultValue={phone}
                    onChange={e => setPhone(e.target.value)}

                  />
                </div>
                <div className="form-group">
                  <label htmlFor="phone" className="font-weight-600 font14">
                    New Password(If you want to change)
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="password"
                    aria-describedby="password"
                    placeholder=""
                    required=""
                    defaultValue={password}
                    onChange={e => setPassword(e.target.value)}

                  />
                </div>
              </form>
              <label className="font-weight-600 mb-1 mt-2">
                Choose the access level
              </label>
              <p className="mt-0 font14 d-none">
                Learn more about <a href="#">account access levels.</a>
              </p>
              <div className="border p-2 mb-2">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio1"
                    value={1}
                    onChange={e => setUser_type(e.target.value)}
                  />
                  <label className="form-check-label" htmlFor="inlineRadio1">
                    Admin
                  </label>
                </div>
              </div>
              <div className="border p-2">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio2"
                    value={2}
                    onChange={e => setUser_type(e.target.value)}
                  />
                  <label className="form-check-label" htmlFor="inlineRadio2">
                    User/Agent
                  </label>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button type="submit" className="btn btn-primary" onClick={handleFormSubmit} >
                {uid > 1 ? "Update" : "Invite"} team member
              </button>
              <button
                type="submit"
                className="btn btn-secondary"
                data-dismiss="modal"
                onClick={e => setUid(0)}
              >
                Cancel
              </button>
              <button type="submit" className={`btn btn-danger d-${uid > 1 ? '' : 'none'}`} onClick={DeleteUser} >
                DELETE
              </button>
            </div>
          </div>
        </div>
      </div>

    </>

  )
}

export default UpdateUser;