const CommonVariables = {
    ApiBase: 'https://crm.inspiretelecom.co.uk/api/',
    ApiUrl: 'https://crm.inspiretelecom.co.uk/api/api.php',
    ApiUrlC: 'https://crm.inspiretelecom.co.uk/api/api-common.php',
    // ApiBase: 'http://localhost/inspireapp/api/',
    // ApiUrl: 'http://localhost/inspireapp/api/api.php',
    // ApiUrlC: 'http://localhost/inspireapp/api/api-common.php',
    ApiKey: 'taranakisolar',
    lead_status: [
        { 'value': '0', 'text': 'Select' },
        { 'value': '1', 'text': 'Deal', 'lcolor': 'btn-success' },
        { 'value': '5', 'text': 'Repitch' },
        { 'value': '9', 'text': 'BT Sport/ Entertainment' },
        { 'value': '6', 'text': 'Bill Below £30' },
        { 'value': '7', 'text': 'Bill Below £40' },
        { 'value': '4', 'text': 'Pipeline' },
        { 'value': '10', 'text': 'Call Back' },
        { 'value': '13', 'text': 'Call Back - After 5PM' },
        { 'value': '11', 'text': 'No Answer', 'lcolor': 'btn-info' },
        { 'value': '12', 'text': 'New Contract' },
        { 'value': '2', 'text': 'DNC' },
        { 'value': '3', 'text': 'Incompatible' },
        { 'value': '8', 'text': 'None' },
        { 'value': '14', 'text': 'Different Provider' }
    ],
    status_label: [
        { 'value': '0', 'text': 'Select' },
        { 'value': '1', 'text': 'Deal', 'lcolor': 'btn-success' },
        { 'value': '5', 'text': 'Repitch' },
        { 'value': '9', 'text': 'BT Sport/ Entertainment' },
        { 'value': '6', 'text': 'Bill Below £30' },
        { 'value': '7', 'text': 'Bill Below £40' },
        { 'value': '4', 'text': 'Pipeline' },
        { 'value': '10', 'text': 'Call Back' },
        { 'value': '13', 'text': 'Call Back - After 5PM' },
        { 'value': '11', 'text': 'No Answer', 'lcolor': 'btn-info' },
        { 'value': '12', 'text': 'New Contract' },
        { 'value': '2', 'text': 'DNC' },
        { 'value': '3', 'text': 'Incompatible' },
        { 'value': '8', 'text': 'None' },
        { 'value': '14', 'text': 'Different Provider' }
    ]

};

export default CommonVariables;
