import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import CommonVariables from './CommonVariables';
import "datatables.net-dt/js/dataTables.dataTables";
import $ from 'jquery';
import { useSelector } from 'react-redux';
import { AuthKeyCheck } from './Helpers';

const LogListDetail = (props) => {
    console.log("props",props);
    const [lidNumber, setLidNumber] = useState(props.lid);
    const [searchValue, setSearchValue] = useState("");
    const userdata = useSelector(state => state.data.entities)
    const [token, setToken] = useState(userdata.session_key);
    useEffect(() => {
        setToken(userdata.session_key)
    }, [userdata])
    // console.log(props);
    useEffect(() => {
        $('#logleaddetail').DataTable({
            "searching": false,
            search: {
                "search": searchValue
            },
            order: [],
            columnDefs: [{
                targets: '_all',
                // 'targets': [0, 7, 8], /* column index [0,1,2,3]*/
                'orderable': false, /* true or false */
            }],
            lengthMenu: [
                [100, 250, 500, 1000, 5000],
                [100, 250, 500, 1000, 5000],
            ],
            processing: true,
            serverSide: true,
            ajax: {
                url: CommonVariables.ApiUrl,
                type: "POST",
                data: function (d) {
                    d.action = "call_log_detail";
                    d.id = lidNumber;
                    var searchValue = d.search.value;
                    setSearchValue(searchValue);
                },
                beforeSend: function (request) {
                    // Fix: Add a space after 'Bearer'
                    request.setRequestHeader('Authorization', 'Bearer ' + token);
                  }
            },
            success: function(apiresponse){
                AuthKeyCheck(apiresponse.urefid)
            },

            columns: [
                {
                    data: 'cby_label',
                },
                {
                    data: 'cdate',
                },
                {
                    data: 'cstatus',
                },
            ]
        });
    }, [])
    return (
        <div className={"modal fade " + (lidNumber > 0 ? 'show d-block' : '')} id="modal_log_detail">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-body" style={{ height: '100vh', overflow: 'auto' }}>
                        <div className="form-row">
                            <div className="container-fluid p-0" style={{ overflow: 'hidden' }}>
                                <div className="p-0 leads coustomerlead">
                                    <div className="row">
                                        <div className="col-xl-11 col-11">
                                            <h4 className="mb-0 prevent-select">Log Details
                                            </h4>
                                        </div>
                                        <div className="col-xl-1 col-1">
                                            Close
                                        </div>
                                    </div>
                                    <div className="bg-white padding10 p-0 mt-3">
                                        <div className="table-responsive">
                                            <table
                                                className="table table-striped table-hover"
                                                id="logleaddetail"
                                            >
                                                <thead>
                                                    <tr>
                                                        <th>Updated By</th>
                                                        <th>Updated On</th>
                                                        <th>Status of Log</th>
                                                        {/* <th>Action</th> */}
                                                        {/* <th>Email Address</th> */}
                                                        {/* <th>Action</th> */}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default LogListDetail
