import React, { Component } from 'react'

export default class CustomerLead extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tbldata: []
    }
  }
  render() {
    return (
      <React.Fragment>
        <div className="container-fluid p-0" style={{ overflow: 'hidden' }}>
          <div className="leads coustomerlead">
            <div className="row">
              <div className="col-xl-6 col-9">
                <h4 className="mb-0">Liam Wikeley</h4>
              </div>
              <div className="col-xl-6 col-3"><a href="#" className="float-right"><i className="fa fa-close"></i></a></div>
            </div>
            <div className="bg-white padding10 mt-3">
              <form>
                <div className="row">
                  <div className="col-md-6 col-12">
                    <div className="form-group">
                      <label htmlFor="exampleFormControlInput1">ID</label>
                      <input type="number" className="form-control" id="id" value="1001" readonly="" />
                    </div>
                  </div>
                  <div className="col-md-6 col-12">
                    <div className="form-group">
                      <label htmlFor="assignee">Assignee</label>
                      <input type="name" className="form-control" id="assignee" value="Jess Ruberto" readonly="" />
                    </div>
                  </div>
                  <div className="col-xl-2 col-3">
                    <div className="form-group">
                      <label htmlFor="title">Title</label>
                      <input type="name" className="form-control" id="title" value="Mr" />
                    </div>
                  </div>
                  <div className="col-xl-5 col-9">
                    <div className="form-group">
                      <label htmlFor="customername">Customer Name</label>
                      <input type="name" className="form-control" id="customername" value="John Smith" />
                    </div>
                  </div>
                  <div className="col-xl-5 col-12">
                    <div className="form-group">
                      <label htmlFor="number">Number</label>
                      <input type="number" className="form-control" id="number" value="01474333012" />
                    </div>
                  </div>
                  <div className="col-md-6 col-12">
                    <div className="form-group">
                      <label htmlFor="email">Email Address</label>
                      <input type="email" className="form-control" id="email" value="test@gmail.com" />
                    </div>
                  </div>
                  <div className="col-md-6 col-12">
                    <div className="form-group">
                      <label htmlFor="mobilenumber">Mobile Number</label>
                      <input type="number" className="form-control" id="mobilenumber" value="07973942913" />
                    </div>
                  </div>
                  <div className="col-md-6 col-12">
                    <div className="form-group">
                      <label htmlFor="postcode">Tag Postcode</label>
                      <input type="text" className="form-control" id="postcode" value="ME2 4HU" />
                    </div>
                  </div>
                  <div className="col-md-6 col-12">
                    <div className="form-group">
                      <label htmlFor="broadband">Broadband Type</label>
                      <input type="text" className="form-control" id="broadband" value="G" />
                    </div>
                  </div>
                  <div className="col-md-6 col-12">
                    <div className="form-group">
                      <label htmlFor="address">Address</label>
                      <textarea className="form-control" id="address" rows="6">Address 1
                        Address 2
                        Address 3
                        Postcode
                        Town
                        County</textarea>
                    </div>
                  </div>
                  <div className="col-md-6 col-12">
                    <div className="form-group">
                      <label htmlFor="additional">Additional CLI's</label>
                      <textarea className="form-control" id="additional" rows="6">01474909090</textarea>
                    </div>
                  </div>
                  <div className="col-md-6 col-12">
                    <div className="form-group">
                      <label htmlFor="amount">Amount</label>
                      <input type="text" className="form-control" id="amount" value="£50" />
                    </div>
                  </div>
                  <div className="col-md-6 col-12">
                    <div className="form-group">
                      <label htmlFor="leadstatus">Lead Status</label>
                      <select className="form-control" id="leadstatus">
                        <option>Select</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6 col-12">
                    <div className="form-group">
                      <label htmlFor="businessname">Business Name</label>
                      <input type="name" className="form-control" id="businessname" value="John Smith Ltd" />
                    </div>
                  </div>
                  <div className="col-md-6 col-12">
                    <div className="form-group">
                      <label htmlFor="currentprovider">Current Provider</label>
                      <input type="text" className="form-control" id="currentprovider" value="BT" />
                    </div>
                  </div>
                  <div className="col-md-6 col-12">
                    <div className="form-group">
                      <label htmlFor="address">Notes</label>
                      <textarea className="form-control" id="address" rows="7"></textarea>
                    </div>
                  </div>
                  <div className="col-md-6 col-12">
                    <div className="form-group">
                      <label htmlFor="date">Call Back Date</label>
                      <input type="text" className="form-control" id="date" value="1st January 2020" />
                    </div>
                    <div className="form-group">
                      <label htmlFor="time">Call Back Time</label>
                      <input type="text" className="form-control" id="time" value="11:00am" />
                    </div>
                    <div className='button-header text-right mt-2'>
                    <button type="button" className="btn btn-primary">SAVE</button></div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </React.Fragment >
    )
  }
}
