import React, { useState, useEffect } from 'react'
import SideMenu from './SideMenu'
import { Link } from 'react-router-dom'
import $ from 'jquery'
import axios from 'axios'
import CommonVariables from './CommonVariables'
import TopBand from './inc/TopBand'
import UpdateUser from './UpdateUser'
import { useSelector } from 'react-redux'
import { AuthKeyCheck } from './Helpers'

const Team = (props) => {
    const [noagents, setNoagents] = useState(0);
    const [padmins, setPadmins] = useState(0);
    const [tleads, setTleads] = useState(0);
    const [tbldata, setTblData] = useState([]);
    const [updateDTable, setUpdateDTable] = useState(0)
    const [uid, setUid] = useState(0)
    const userdata = useSelector(state => state.data.entities)
    const [token, setToken] = useState(userdata.session_key);
    useEffect(() => {
        setToken(userdata.session_key)
    }, [userdata])
    const ShowEditPopup = (props) => {
        if (props.uid) {
            return <UpdateUser
                uid={props.uid}
                setupdateDTable={setupdateDTable}
            />;
        }
        return;
    }
    const setupdateDTable = () => {
        setUpdateDTable(updateDTable + 1)
        setUid(0)
    }
    const ClickEditPopup = (props) => {
        if (uid === props) {
            alert("You have recently closed this user, Try again.")
            setUid(0)
            return
        }
        setUid(props)
    }
    //One time Load
    useEffect((e) => {
        const fetchTableData = async (e) => {
            const fbody = new FormData();
            fbody.append("action", "user_list");
            await axios({
                method: "POST",
                url: CommonVariables.ApiUrl,
                data: fbody,
                headers: { 'Authorization': `Bearer ${token}`}
            })
                .then(function (apiresponse) {
                    AuthKeyCheck(apiresponse.data.urefid)
                    setTblData(apiresponse.data.data);
                    setPadmins(apiresponse.data.padmins);
                    setTleads(apiresponse.data.tleads);
                    setNoagents(apiresponse.data.recordsTotal - apiresponse.data.padmins);
                    // console.log("LINE108");
                    // console.log(apiresponse);
                })
                .catch(function (apierror) {
                    console.log(apierror)
                });
        }

        fetchTableData()
        console.log("LINE116");
        setTimeout(function () {
            $('#tblfreshleads').DataTable({
                searching: true,
                "bDestroy": true
            });
        }, 1000);
    }, [updateDTable])

    return (
        <React.Fragment>
            <UpdateUser uid={0} />
            <div className="container-fluid p-0" style={{ overflow: 'hidden' }}>
                <SideMenu team="active" />
                <div className="body_">
                    <TopBand
                        pname="Team"
                    />
                    <div className="section bg-light">
                        <div className="row">
                            <div className="col-xl-3 col-sm-6">
                                <div className="das-box color">
                                    <h5>Number of agents</h5>
                                    <div className="text-border"></div>
                                    <h1 className="mb-0">{noagents}</h1>
                                </div>
                            </div>
                            <div className="col-xl-3 col-sm-6" >
                                <div className="das-box color1" >
                                    <h5>Portal Admins</h5>
                                    <div className="text-border">
                                    </div>
                                    <h1 className="mb-0">
                                        {padmins}
                                    </h1>
                                </div>
                            </div>
                            <div className="col-xl-3 col-sm-6">
                                <div className="das-box color2">
                                    <h5>Total Leads</h5>
                                    <div className="text-border"></div>
                                    <h1 className="mb-0">{tleads}</h1>
                                </div>
                            </div>
                            <div className="col-xl-3 col-sm-6 member">
                                <button type="button" className="btn btn-primary" onClick={e => ClickEditPopup(1)}>invite a team member</button>
                            </div>
                        </div>
                        <div className="darkbg">
                            <div className="bg-white padding10">
                                <ShowEditPopup
                                    uid={uid}
                                    compo='/team'
                                />
                                <div className="table-responsive">
                                    <table id="tblfreshleads" className="table" style={{ width: '100 %' }}>
                                        <thead>
                                            <tr>
                                                <th>Team Member</th>
                                                <th>Status</th>
                                                <th>Access Level</th>
                                                <th>Email</th>
                                                <th>2-FA</th>
                                                <th>Last Active</th>
                                                <th>Created</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {tbldata.map((result) => {
                                                return (
                                                    <tr key={result.id}>
                                                        <th onClick={e => ClickEditPopup(result.id)}>{result.name}</th>
                                                        <td><button type="button" className="btn">{result.cstatus}</button></td>
                                                        <td>{result.user_type}</td>
                                                        <td>{result.email}</td>
                                                        <td>{result.tfa}</td>
                                                        <td>{result.cdate}</td>
                                                        <td>{result.cdate}</td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default Team;