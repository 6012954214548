import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import SideMenu from "./SideMenu";
import TopBand from "./inc/TopBand"
import axios from "axios";
import UpdateLead from "./UpdateLead";
import $, { isEmptyObject } from 'jquery'
import CommonVariables from './CommonVariables'
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AssignLeads from './inc/AssignLeads';
import { AuthKeyCheck } from "./Helpers";
const selectAllCheckboxes = () => {
	var check_state = (document.getElementById('customCheck1').checked);
	const checkboxes = document.querySelectorAll("input[name='chckbx']");
	checkboxes.forEach((cb) => { cb.checked = check_state; });
}

const AgentPipeline = () => {
	const navigate = useNavigate();
	const userdata = useSelector(state => state.data.entities)
	const [lid, setLid] = useState(0)
	const [updateDTable, setUpdateDTable] = useState(0)
	const [tblData, setTblData] = useState([])
	const [agents, setAgents] = useState([])
	const [toagent, setToagent] = useState(0)
	const [selectedAgents, setSelectedAgents] = useState("")
	const [token, setToken] = useState(userdata.session_key);
    useEffect(() => {
        setToken(userdata.session_key)
    }, [userdata])

	useEffect(() => {
		const fetchTableData = async () => {
			const fbody = new FormData();
			fbody.append("action", "user_list");
			fbody.append("user_type", 2);
			await axios({
				method: "POST",
				url: CommonVariables.ApiUrl,
				data: fbody,
				headers: { 'Authorization': `Bearer ${token}`},
			})
				.then(function (apiresponse) {
					AuthKeyCheck(apiresponse.data.urefid)
					setAgents(apiresponse.data.data)
					// console.log("LINE108");
				})
				.catch(function (apierror) {
					console.log(apierror);
				});
		};
		fetchTableData();
	}, []
	)

	const Pullback = async (props) => {
		let lead_vals = $("input[name=chckbx]:checked").map(function () {
			return this.value;
		}).get().join(",");
		if (isEmptyObject(lead_vals)) {
			alert("Please select a lead to move.")
			return;
		}
		//Moving leads
		const fbody = new FormData();
		fbody.append("lead_ids", lead_vals);
		fbody.append("actiontype", "usedlead");
		fbody.append("action", "lead_bulk_update");
		await axios({
			method: "POST",
			url: CommonVariables.ApiUrl,
			data: fbody,
			headers: { 'Authorization': `Bearer ${token}`}
		})
			.then(function (apiresponse) {
				AuthKeyCheck(apiresponse.data.urefid)
				setUpdateDTable(updateDTable + 1)
				alert(apiresponse.data.resmsg)
				window.location.reload();

			})
			.catch(function (apierror) {
				console.log(apierror)
			});

	}




	const onExport = (props) => {
		alert("Please wait while we export data.");
	}
	const ResetLid = () => {
		setLid(0);
	}
	///Edit Popup Function
	const ShowEditPopup = (props) => {
		if (props.lid) {
			if (userdata.user_type == 1) {
				// alert("Admin not allowed to update leads.");
				//return
			}
			return <UpdateLead
				lid={props.lid}
				compo={props.compo}
				ResetLid={ResetLid}
				agents={agents}
			/>;
		}
		return;
	}
	const ClickEditPopup = (props) => {
		if (lid === props) {
			alert("You have recently closed this lead, Try again.")
			setLid(0)
			return
		}
		setLid(props)
	}
	useEffect(() => {
		if ($.fn.DataTable.isDataTable('#tblfreshleads')) {
			$('#tblfreshleads').DataTable().destroy();
		}
		$('#tblfreshleads tbody').empty();
		$('#tblfreshleads').DataTable({
			"searching": false,
			order: [],
			columnDefs: [{
				'targets': [0, 7, 8], /* column index [0,1,2,3]*/
				'orderable': false, /* true or false */
			}],
			lengthMenu: [
				[100, 250, 500, 1000, 5000],
				[100, 250, 500, 1000, 5000],
			],
			processing: true,
			serverSide: true,
			ajax: {
				url: CommonVariables.ApiUrl,
				type: "POST",
				data: function (d) {
					d.action = "lead_list";
					d.actiontype = "agentpipeline";
					d.agentids = selectedAgents;
				},
				beforeSend: function (request) {
					// Fix: Add a space after 'Bearer'
					request.setRequestHeader('Authorization', 'Bearer ' + token);
				  }
			},
			success: function(apiresponse){
				AuthKeyCheck(apiresponse.urefid)
			},
			columns: [
				{
					"data": 'id',
					render: function (data, type, row) {
						return '<input name="chckbx" type="checkbox" className="custom-control-input" id=customCheck' + row['id'] + ' value=' + row['id'] + ' />';
					}
				},
				{
					data: 'refid',
					render: function (data, type, row) {
						return '<p class="prevent-select">' + row['refid'] + '</p>';
					}

				},
				{
					data: 'agent_name',
					render: function (data, type, row) {
						return '<p class="prevent-select">' + row['agent_name'] + '</p>';
					}

				},
				{
					data: 'business_name',
					render: function (data, type, row) {
						return '<p class="prevent-select">' + row['business_name'] + '</p>';
					}
				},
				{
					data: 'address',
					render: function (data, type, row) {
						return '<p class="prevent-select">' + row['address'] + '</p>';
					}
				},
				{
					data: 'number',
					render: function (data, type, row) {
						return '<a class="prevent-select" href="tel:' + row['number'] + '">' + row['number'] + '</a>';
					}
				},
				{
                    data: 'fttpsogea',
                    render: function (data, type, row) {
                        return '<p class="prevent-select">' + row['fttpsogea'] + '</p>'
                    }
                },
				{
					data: 'bb_type',
					render: function (data, type, row) {
						return '<p class="prevent-select">' + row['bb_type'] + '</p>';
					}
				},
				// {
				// 	"data": 'draw',
				// 	render: function (data, type, row) {
				// 		return '<button data-id="' + row['id'] + '" type="button" class="btn btn-' + row['status_color'] + '">' + row['status_txt'] + '</button>';
				// 	}
				// },
				{
                    "data": 'status_txt',
                    "render": function (data, t, row) {
                      var $select = $("<select  data-id='" + row['id'] + "'  class='status_select btn btn-xs btn btn-" + row['status_color'] + "'></select>", {
                        "id": row[0] + "start",
                        "value": data
                      });
                      $.each(CommonVariables.lead_status, function (k, v) {
                        var $option = $("<option></option>", {
                          "text": v.text,
                          "value": v.value
                        });
                        if (data === v.text) {
                          $option.attr("selected", "selected")
                        }
                        $select.append($option);
                      });
                      $select.children('option[value="0"]').remove();
                      return $select.prop("outerHTML");
                    }
                  },
				{
					"data": 'draw',
					render: function (data, type, row) {
						return '<button data-id="' + row['id'] + '" type="button" class="btn btn-secondary">Enter</button>';
					}
				}
			]
		});
		$('#tblfreshleads tbody').on('click', 'button', function () {
			var clid = $(this).attr('data-id')
			ClickEditPopup(clid)
		});
		$('#tblfreshleads tbody').on('change', 'select', function () {
            var clid = $(this).attr('data-id')
            var dataval = $(this).val()
            var prams = { "clid": clid, "dataval": dataval }
            UpdateLeadStatus(prams)
          });
	}, [selectedAgents])
	const UpdateLeadStatus = (props) => {
        const updateData = async (props) => {
          const fbody = new FormData();
          fbody.append("lead_id", props.clid);
          fbody.append("cstatus", props.dataval);
          fbody.append("action", "lead_update");
          await axios({
            method: "POST",
            url: CommonVariables.ApiUrl,
            data: fbody,
            headers: { 'Authorization': `Bearer ${token}` }
          })
            .then(function (apiresponse) {
				AuthKeyCheck(apiresponse.data.urefid)
            })
            .catch(function (apierror) {
              console.log(apierror)
            });
        }
        updateData(props
        )
      }

	if (userdata.user_type != 1) {
		navigate("/user");
	}
	const ClickEditPopup2 = () => {
		setToagent(toagent + 1)
	}
	const AssignToAgent = (props) => {
		if (props.toagent > 0) {
			return <AssignLeads
				ResetToAgent={ResetToAgent}
				hideinput="1"
			/>;
		}
	}
	const ResetToAgent = (props) => {
		setToagent(0);
		setSelectedAgents(props)
	}


	const preventCopyPaste = (e) => {
		e.preventDefault()
		alert("Copying and pasting is not allowed!")
	}

	// delete all
	const DeleteAll = async (props) => {
		let lead_vals = $("input[name=chckbx]:checked").map(function () {
			return this.value;
		}).get().join(",");
		if (isEmptyObject(lead_vals)) {
			alert("Please select a lead to delete.")
			return;
		}
		if (window.confirm("Are you sure you wish to delete this lead?") == true) {

			const fbody = new FormData();
			fbody.append("lead_ids", lead_vals);
			fbody.append("actiontype", "deleteleads");  //leadpot - use this for pull back
			fbody.append("action", "lead_bulk_update");
			await axios({
				method: "POST",
				url: CommonVariables.ApiUrl,
				data: fbody,
				headers: {'Authorization': `Bearer ${token}`}
			})
				.then(function (apiresponse) {
					AuthKeyCheck(apiresponse.data.urefid)
					setUpdateDTable(updateDTable + 1)
					alert(apiresponse.data.resmsg)
					window.location.reload();
				})
				.catch(function (apierror) {
					console.log(apierror)
				});
		}


	}
	return (
		<>
			<AssignToAgent
				toagent={toagent}
			/>
			<div className="container-fluid p-0" style={{ overflow: "hidden" }}>
				<SideMenu agentpipeline="active" />
				<div className="body_">
					<TopBand
						pname="Agent Pipeline"
					/>
					<div className="leads">
						<div className="darkbg">
							<div className="bg-white padding10">
								<div className="button-header">
									<button onClick={e => DeleteAll()} type="button" className="btn btn-danger">
										Delete All
									</button>
									<button type="button" className="btn btn-primary" onClick={e => ClickEditPopup2()}>Select Agents</button>
									<button type="button" className="btn btn-info" data-toggle="modal" data-target="#modal-default" onClick={e => Pullback()}>
										Pull back
									</button>

									<a href={`${CommonVariables.ApiBase}export-lead-data.php?action_type=agentpipeline&agentids=${selectedAgents}`}>
										<button onClick={e => onExport()} type="button" className="btn btn-success">
											Export
										</button>
									</a>
								</div>
								<ShowEditPopup
									lid={lid}
									compo='/agentpipeline'
								/>
								<div className="table-responsive">
									<table
										id="tblfreshleads"
										className="table"
										style={{ width: "100%" }}
									>
										<thead>
											<tr>
												<th>

													<div className="custom-control custom-checkbox">
														<input
															type="checkbox" className="custom-control-input" id="customCheck1"
															onClick={selectAllCheckboxes}
														/>
														<label className="custom-control-label" htmlFor="customCheck1"></label>
													</div>
												</th>
												<th>ID</th>
												<th>Agent</th>
												<th>Name</th>
												<th>Address</th>
												<th>Number</th>
												<th>SOGEA/FTTP</th>
												<th>BB Type</th>
												<th>Status</th>
												<th>Notes</th>

											</tr>
										</thead>
										<tbody>

											{tblData.map((result) => {
												return (
													<tr key={result.id}>
														<td className="pl-2"><div className="custom-control custom-checkbox">
															<input name="chckbx" type="checkbox" className="custom-control-input" id={`customCheck${result.id}`} value={result.id} />
															<label className="custom-control-label" htmlFor={`customCheck${result.id}`}></label>
														</div></td>
														<td>{result.agent}</td>
														<td>{result.business_name}</td>
														<td>{result.address}</td>
														<td>{result.number}</td>
														<td>{result.postcode}</td>
														<td>{result.bb_type}</td>
														<td>
															<button onClick={e => ClickEditPopup(result.id)} type="button" className={`btn btn-${result.status_color}`}>{result.status_txt}</button></td>
														<td><button onClick={e => ClickEditPopup(result.id)} type="button" className="btn btn-secondary">
															Enter
														</button></td>
													</tr>
												)
											})}

										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
export default AgentPipeline;
