import React, { useEffect, useState } from 'react'
import SideMenu from './SideMenu'
import axios from 'axios'
import CommonVariables from './CommonVariables'
import { $ } from 'jquery'
import UpdateLead from './UpdateLead'
//Redux Starts
import { useSelector } from 'react-redux'
import TopBand from './inc/TopBand'
import { useNavigate } from "react-router-dom"
import { AuthKeyCheck } from './Helpers'
//Redux Ends
const selectAllCheckboxes = () => {
    var check_state = (document.getElementById('customCheck1').checked);
    const checkboxes = document.querySelectorAll("input[name='chckbx']");
    checkboxes.forEach((cb) => { cb.checked = check_state; });
}
const UserHome = (props) => {
    const navigate = useNavigate();
    const usertype = useSelector(state => state.data.entities)
    // console.log("usertype", usertype);
    const [token, setToken] = useState(usertype.session_key);
    useEffect(() => {
        setToken(usertype.session_key)
    }, [usertype])
    const [pipeline, setPipeline] = useState(0);
    const [lid, setLid] = useState(0)
    const [updateDTable, setUpdateDTable] = useState(0)
    const [tblData, setTblData] = useState([])
    const [cby, setCby] = useState(usertype.id)
    const [overview, setOverview] = useState([""])
    const [agents, setAgents] = useState([])
    useEffect(() => {
        const fetchTableData = async () => {
            const fbody = new FormData();
            fbody.append("action", "user_list");
            fbody.append("user_type", 2);
            await axios({
                method: "POST",
                url: CommonVariables.ApiUrl,
                data: fbody,
                headers: { 'Authorization': `Bearer ${token}` },
            })
                .then(function (apiresponse) {
                    AuthKeyCheck(apiresponse.data.urefid)
                    setAgents(apiresponse.data.data)
                    //console.log("LINE108");
                })
                .catch(function (apierror) {
                    console.log(apierror);
                });
        };
        fetchTableData();
    }, []
    )
    const ResetLid = () => {
        setLid(0);
    }
    ///Edit Popup Function
    const ShowEditPopup = (props) => {
        if (props.lid) {
            return <UpdateLead
                lid={props.lid}
                compo={props.compo}
                ResetLid={ResetLid}
                agents={agents}
            />;
        }
        return;
    }
    const ClickEditPopup = (props) => {
        if (lid == props) {
            alert("You have recently closed this lead, Try again.")
            setLid(0)
            return
        }
        setLid(props)
    }
    //One time Load
    useEffect(() => {
        if (cby == undefined) {
            // console.log("undefined---" + usertype.id);
            // console.log(usertype);
            setCby(usertype.id)
            return
        }
        const fetchTableData = async () => {
            const fbody = new FormData();
            fbody.append("actiontype", "agentpipeline");
            fbody.append("action", "lead_list");
            fbody.append("duration", "today");
            fbody.append("cby", cby);
            await axios({
                method: "POST",
                url: CommonVariables.ApiUrl,
                data: fbody,
                headers: { 'Authorization': `Bearer ${token}` }
            })
                .then(function (apiresponse) {
                    AuthKeyCheck(apiresponse.data.urefid)
                    setTblData(apiresponse.data.data)
                    setPipeline(apiresponse.data.recordsTotal)
                    //console.log("LINE108");
                })
                .catch(function (apierror) {
                    console.log(apierror)
                });
        }
        fetchTableData()
        //console.log("LINE116");
        setTimeout(function () {
            $('#tblfreshleads').DataTable({
                searching: false,
                "bDestroy": true
            });
        }, 1000);
    }, [cby])
    useEffect(() => {
        if (cby == undefined) {
            console.log("undefined---" + usertype.id);
            setCby(usertype.id)
            return
        }
        const fetchTableData = async () => {
            const fbody = new FormData();
            fbody.append("action", "dashboard");
            fbody.append("cby", cby);
            await axios({
                method: "POST",
                url: CommonVariables.ApiUrl,
                data: fbody,
                headers: { 'Authorization': `Bearer ${token}` }
            })
                .then(function (apiresponse) {
                    AuthKeyCheck(apiresponse.data.urefid)
                    if (apiresponse.data.rescode !=0) {
                        setOverview(apiresponse.data.data)
                    }
                })
                .catch(function (apierror) {
                    console.log(apierror)
                });
        }
        fetchTableData()
    }, [cby])
    useEffect(() => {
        const fetchTableData = async () => {
            const fbody = new FormData();
            fbody.append("actiontype", "agentpipeline");
            fbody.append("action", "lead_list");
            fbody.append("cby", usertype.id);
            await axios({
                method: "POST",
                url: CommonVariables.ApiUrl,
                data: fbody,
                headers: { 'Authorization': `Bearer ${token}` }
            })
                .then(function (apiresponse) {
                    AuthKeyCheck(apiresponse.data.urefid)
                    setPipeline(apiresponse.data.recordsTotal)
                    //console.log("LINE108");
                })
                .catch(function (apierror) {
                    console.log(apierror)
                });
        }
        fetchTableData()
    }, [updateDTable])
    if (usertype.user_type != 2) {
        navigate("/");
    }
    return (
        <React.Fragment>
            <div className="container-fluid p-0" style={{ overflow: 'hidden' }}>
                <SideMenu home="active" />
                <div className="body_">
                    <TopBand
                        pname="Dashboard"
                    />
                    <div className="section bg-light dash-box">
                        <h5 className="mt-3 mb-3 prevent-select">Leads Overview</h5>
                        <div className="row">
                            <div className="col-xl-3 col-sm-6">
                                <div className="das-box box-height color">
                                    <h5 className='prevent-select'>Deals to date</h5>
                                    <div className="text-border"></div>
                                    <h1 className="mb-0 prevent-select">{overview.totalleads}</h1>
                                </div>
                            </div>
                            <div className="col-xl-3 col-sm-6">
                                <div className="das-box box-height color1">
                                    <h5 className='prevent-select'>Commission written</h5>
                                    <div className="text-border"></div>
                                    <h1 className="mb-0 prevent-select">{overview.commwritten}</h1>
                                </div>
                            </div>
                            <div className="col-xl-3 col-sm-6">
                                <div className="das-box box-height color2">
                                    <h5 className='prevent-select'>MY Leads</h5>
                                    <div className="text-border"></div>
                                    <h1 className="mb-0 prevent-select">{overview.freshleads}</h1>
                                </div>
                            </div>
                            <div className="col-xl-3 col-sm-6">
                                <div className="das-box box-height color3">
                                    <h5 className='prevent-select'>My Pipeline</h5>
                                    <div className="text-border"></div>
                                    <h1 className="mb-0 prevent-select">{pipeline}</h1>
                                </div>
                            </div>
                        </div>
                        <h4 className='mt-5 prevent-select'>Today Pipeline <a className='float-right font14 prevent-select' href='/Up_Pipeline'>View More</a></h4>
                        <div className="leads">
                            <div className="darkbg">
                                <div className="bg-white padding10">
                                    <ShowEditPopup
                                        lid={lid}
                                        compo='/user'
                                    />
                                    <div className="table-responsive">
                                        <table
                                            id="example"
                                            className="table"
                                            style={{ width: "100%" }}
                                        >
                                            <thead>
                                                <tr>
                                                    <th>
                                                        <div className="custom-control custom-checkbox">
                                                            <input
                                                                type="checkbox" className="custom-control-input" id="customCheck1"
                                                                onClick={(e) => selectAllCheckboxes()}
                                                            />
                                                            <label className="custom-control-label" htmlFor="customCheck1"></label>
                                                        </div>
                                                    </th >
                                                    <th>Name</th>
                                                    <th>Number</th>
                                                    <th>SOGEA/FTTP</th>
                                                    <th>BB Type</th>
                                                    <th>Status</th>
                                                    <th>Date</th>
                                                    <th>Time</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {tblData.map((result) => {
                                                    //console.log(result);
                                                    return (
                                                        <tr key={result.id}>
                                                            <td className="pl-0"><div className="custom-control custom-checkbox">
                                                                <input name="chckbx" type="checkbox" className="custom-control-input" id={`customCheck${result.id}`} value={result.id} />
                                                                <label className="custom-control-label" htmlFor={`customCheck${result.id}`}></label>
                                                            </div></td>
                                                            <td><a href="#" onClick={e => ClickEditPopup(result.id)}>{result.business_name}</a></td>
                                                            <td><a href="tel://">{result.number}</a></td>
                                                            <td>{result.fttpsogea}</td>
                                                            <td>{result.bb_type}</td>
                                                            <td>
                                                                <button type="button" onClick={e => ClickEditPopup(result.id)} className={`btn btn-${result.status_color}`}>{result.status_txt}</button></td>
                                                            <td>{result.date}</td>
                                                            <td>{result.time}</td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment >
    )
}
export default UserHome
