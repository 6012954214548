import React, { useEffect, useState, Component } from 'react'
import { Link, useParams } from 'react-router-dom'
import SideMenu from './SideMenu'
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import $, { isEmptyObject } from 'jquery';
import axios from 'axios';
import CommonVariables from './CommonVariables'
import TopBand from './inc/TopBand';
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { AuthKeyCheck } from './Helpers';
const ImportLog = (props) => {
    const navigate = useNavigate();
    const userdata = useSelector(state => state.data.entities)
    const [token, setToken] = useState(userdata.session_key);
    useEffect(() => {
        setToken(userdata.session_key)
    }, [userdata])
    if (userdata.user_type != 1) {
        navigate("/user");
    }
    const [lid, setLid] = useState(0)
    const [toagent, setToagent] = useState(0)
    const [btnsubmit, setBtnsubmit] = useState("")
    const [selectedFile, setSelectedFile] = useState("")
    const [tblData, setTblData] = useState([])
    const [result, setResult] = useState()
    const [updateDTable, setUpdateDTable] = useState(0)
    const [agents, setAgents] = useState([])
    const [startc, setStartc] = useState(0)
    const [endc, setEndc] = useState(5000)
    useEffect(() => {
        const fetchTableData = async () => {
            const fbody = new FormData();
            fbody.append("action", "user_list");
            fbody.append("user_type", 2);
            await axios({
                method: "POST",
                url: CommonVariables.ApiUrl,
                data: fbody,
                headers: {'Authorization': `Bearer ${token}`},
            })
                .then(function (apiresponse) {
                    AuthKeyCheck(apiresponse.data.urefid)
                    setAgents(apiresponse.data.data)
                    console.log("LINE108");
                })
                .catch(function (apierror) {
                    console.log(apierror);
                });
        };
        fetchTableData();
    }, []
    )
    const ResetLid = () => {
        setLid(0);
    }
    
    const handleInputChange = (event) => {
        console.log("handleInputChange");
        console.log(event);
        setSelectedFile(event.target.files)
        console.log(selectedFile[0]);
    }
    const resetPage = (event) => {
        console.log(event)
        console.log("resetPage")
        window.location.reload();
    }
    useEffect(() => {
        $('#tblfreshleads').DataTable({
            "searching": false,
            order: [],
            columnDefs: [{
                'targets': [], /* column index [0,1,2,3]*/
                'orderable': false, /* true or false */
            }],
            lengthMenu: [
                [100, 250, 500, 1000, 5000],
                [100, 250, 500, 1000, 5000],
            ],
            processing: true,
            serverSide: true,
            ajax: {
                url: CommonVariables.ApiUrl,
                type: "POST",
                data: function (d) {
                    d.action = "importlog";
                },
                beforeSend: function (request) {
                    // Fix: Add a space after 'Bearer'
                    request.setRequestHeader('Authorization', 'Bearer ' + token);
                  }
            },
            success: function(apiresponse){
                AuthKeyCheck(apiresponse.urefid)
            },

            columns: [
                { data: 'cdate' },
                { data: 'business_name' },
                { data: 'address' },
                { data: 'number' },
                { data: 'fttpsogea' },
                
                { data: 'bb_type' },
                {
                    data: 'notes'
                }
            ]
        });
    }, [updateDTable])
    const DeleteAll = async (props) => {
        if (window.confirm("Are you sure you wish to delete this log data?") == true) {
            const fbody = new FormData();
            fbody.append("actiontype", "deleteimport");  //leadpot - use this for pull back
            fbody.append("action", "lead_bulk_update");
            await axios({
                method: "POST",
                url: CommonVariables.ApiUrl,
                data: fbody,
                headers: {'Authorization': `Bearer ${token}`}
            })
                .then(function (apiresponse) {
                    AuthKeyCheck(apiresponse.data.urefid)
                    setUpdateDTable(updateDTable + 1)
                    alert(apiresponse.data.resmsg)
                    window.location.reload();
                })
                .catch(function (apierror) {
                    console.log(apierror)
                });
        }
    }
    const onExport = async (props) => {
        alert("Please wait while we export "+endc+" records, starting from "+startc+" position. Please verify record count after export.")
        return;
    }
    return (
        <>
            <div className="container-fluid p-0" style={{ overflow: 'hidden' }}>
                <SideMenu importlog="active" />
                <div className="body_">
                    <TopBand
                        pname="Import Log"
                    />
                    <div className="leads">
                        <div className="darkbg">
                            <div className="bg-white padding10">
                                <div className="button-header">
                                    <button className="btn btn-success">Start Position</button>
                                    <input className='btn btn-info col-2' type="number"
                                    onChange={e => setStartc(e.target.value)}
                                    value={startc}
                                    />
                                    <button className="btn btn-success">Total Count</button>
                                    <input className='btn btn-info col-2' type="number"
                                    onChange={e => setEndc(e.target.value)}
                                    value = {endc}
                                    />
                                    <button onClick={e => DeleteAll()} type="button" className="btn btn-danger">
                                        Delete All
                                    </button>
                                    <a href={`${CommonVariables.ApiBase}export-log-data.php?start=${startc}&end=${endc}`}  className="btn btn-success"  onClick={e => onExport()}>Export</a> 
                                    <button onClick={e => resetPage(e)} type="button" className="btn btn-success"><i className="fa fa-refresh"></i> Refresh Log</button>
                                </div>
                                <div className="table-responsive">
                                    <table id="tblfreshleads" className="table" style={{ width: '100%' }}>
                                        <thead>
                                            <tr>
                                                <th>Date & Time</th>
                                                <th width="15%">Name</th>
                                                <th width="15%">Address</th>
                                                <th>Number</th>
                                                <th>SOGEA/FTTP</th>
                                                <th>BB Type</th>
                                                <th>Notes</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default ImportLog;