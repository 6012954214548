import React, { Component } from 'react';
import SideMenu from './SideMenu';
import $ from 'jquery';
import axios from 'axios';
import TopBand from './inc/TopBand';
import { useSelector } from 'react-redux';

export default class Import extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: [],
            // userdata: useSelector(state => state.data.entities),
            // token: useSelector(state => state.data.entities.session_key)
        }
    }
    componentDidMount() {
        //Get all users details in bootstrap table
        axios.get('http://localhost/getList.php').then(res => {
            //Storing users detail in state array object
            this.setState({ data: res.data });
        });
        //initialize datatable
        $(document).ready(function () {
            setTimeout(function () {
                $('#example').DataTable();
            }, 1000);
        });
    }
    render() {
        return (
            <React.Fragment>
                <div className="container-fluid p-0" style={{ overflow: 'hidden' }}>
                    <SideMenu freshleads="active" />
                    <div className="body_">
                    <TopBand
					pname="Import Leads"
					/>
                        <div className="leads">
                            <div className="darkbg">
                                <div className="bg-white padding10">
                                    <div className="button-header">
                                        <button type="button" className="btn btn-warning">Move to lead pot</button>
                                        <button type="button" className="btn btn-info">Import</button>
                                        <button type="button" className="btn btn-primary">Assign to Agents</button>
                                        <button type="button" className="btn btn-success">Export</button>
                                    </div>
                                    <div className="table-responsive">
                                        <table id="example" className="table" style={{ width: '100%' }}>
                                            <thead>
                                                <tr>
                                                    <th> <p className="mb-0">Select All</p>
                                                        <div className="custom-control custom-checkbox">
                                                            <input type="checkbox" className="custom-control-input" id="customCheck1" />
                                                            <label className="custom-control-label" htmlFor="customCheck1"></label>
                                                        </div></th>
                                                    <th>ID</th>
                                                    <th>Name</th>
                                                    <th>Address</th>
                                                    <th>Number</th>
                                                    <th>SOGEA/FTTP</th>
                                                    <th>BB Type</th>
                                                    <th>Status</th>
                                                    <th>Notes</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td className="pl-0"><div className="custom-control custom-checkbox">
                                                        <input type="checkbox" className="custom-control-input" id="customCheck2" />
                                                        <label className="custom-control-label" htmlFor="customCheck2"></label>
                                                    </div></td>
                                                    <td className="prevent-select">1</td>
                                                    <td className="prevent-select">A Smith</td>
                                                    <td className="prevent-select">1 high street..</td>
                                                    <td className="prevent-select"><a href="tel://1234567891">1234567891</a></td>
                                                    <td className="prevent-select">DA11 9TT</td>
                                                    <td className="prevent-select">G</td>
                                                    <td><button type="button" className="btn btn-info">Select</button></td>
                                                    <td><button type="button" className="btn btn-secondary">Enter</button></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment >
        )
    }
}