import React from 'react'
import { Link } from 'react-router-dom'

export default function LogOut() {
  localStorage.removeItem("userdata");
  return (
    <div className="bg-color login">
    <div className="container">
      <div className="row">
        <div className="col-xl-4 col-sm-4"></div>
        <div className="col-xl-4 col-sm-4">
          <div className="box bg-white margintop_226 logout"> <img src="assets/img/logo.png" /><br />
            <Link to="/login1" className="btn btn-primary btn-block">Logout</Link> </div>
        </div>
        <div className="col-xl-4 col-sm-4">
          <div className="login-btn"><Link to="/login" className="btn btn-success">Log in</Link></div>
        </div>
      </div>
    </div>
  </div>
  )
}
