import React, { useState,useEffect } from 'react';
import axios from 'axios';
import CommonVariables from './CommonVariables';
import { isEmptyObject } from 'jquery';
import { useSelector } from 'react-redux';
import { AuthKeyCheck } from './Helpers';
export default function Verify(props) {
	const [userName, setUsername] = useState()
	const [password, setPassword] = useState()
	const userdata = useSelector(state => state.data.entities)
	const [token, setToken] = useState(userdata.session_key);
	useEffect(() => {
		setToken(userdata.session_key)
	}, [userdata])
	const handleFormSubmit = () => {
		const queryParams = new URLSearchParams(window.location.search)
		const ukey = queryParams.get("ukey")
		if (isEmptyObject(userName) || isEmptyObject(password)) {
			alert("Password and Confirm password is mandatory.")
			return
		}
		if (password.length < 6) {
			alert("Password length should not be less than 6 characters.")
			return
		}
		if (userName === password) {
			const fetchTableData = async () => {
				const fbody = new FormData();
				fbody.append("action", "verify_user");
				fbody.append("ukey", ukey);
				fbody.append("password", password);
				await axios({
					method: "POST",
					url: CommonVariables.ApiUrl,
					data: fbody,
					headers: {'Authorization': `Bearer ${token}` },
				})
					.then(function (apiresponse) {
						AuthKeyCheck(apiresponse.data.urefid)
						if (apiresponse.data.rescode === 1) {
							window.location = '/login';
						}
						//setTblData(apiresponse.data.data);
					})
					.catch(function (apierror) {
						console.log(apierror);
					});
			};
			fetchTableData();
		} else {
			alert("Password and Confirm password should be same.");
		}
	}
	return (
		<div className="bg-color login">
			<div className="container">
				<div className="row">
					<div className="col-xl-4 col-sm-3"></div>
					<div className="col-xl-4 col-sm-6">
						<div className="box bg-white margintop_230 loginbox"> <img src="assets/img/logo.png" /><br />
							<div className="row">
								<div className="col-xl-9 col-sm-12 pr-1">
									<input
										type="password"
										className="form-control"
										id="username"
										name="username"
										value={userName}
										onChange={e => setUsername(e.target.value)}
										placeholder="enter your password"
									/>
								</div>
								<div className="col-xl-9 col-sm-12 pr-1">
									<input
										type="password"
										className="form-control"
										id="password"
										name="password"
										value={password}
										onChange={e => setPassword(e.target.value)}
										placeholder="confirm your password"
									/>
								</div>
								<div className="col-xl-3 col-sm-12 pl-1">
									<input className="btn btn-block btn-success" type="submit" onClick={e => handleFormSubmit(e)} value="Go" />
								</div>
							</div>
						</div>
					</div>
					<div className="col-xl-4 col-sm-3"></div>
				</div>
			</div>
		</div>
	)
}
