import React, { useEffect, useState } from 'react'
import SideMenu from './SideMenu'
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import $ from 'jquery';
import axios from 'axios';
import CommonVariables from './CommonVariables'
import UpdateLead from './UpdateLead';
import TopBand from './inc/TopBand';
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import LogList from './LogList';
import { AuthKeyCheck } from './Helpers';
const selectAllCheckboxes = () => {
    var check_state = (document.getElementById('customCheck1').checked);
    const checkboxes = document.querySelectorAll("input[name='chckbx']");
    checkboxes.forEach((cb) => { cb.checked = check_state; });
}
const FreshLeads = (props) => {
    const navigate = useNavigate();
    const userdata = useSelector(state => state.data.entities)
    const [token, setToken] = useState(userdata.session_key);
    useEffect(() => {
        setToken(userdata.session_key)
    }, [userdata])
    const [searchValue, setSearchValue] = useState("");
    if (userdata.user_type != 1) {
        navigate("/user");
    }
    const [lid, setLid] = useState(0)
    const [logid, setlogid] = useState(0)
    const [updateDTable, setUpdateDTable] = useState(0)
    const [agents, setAgents] = useState([])
    const ResetLid = () => {
        setLid(0);
        //setUpdateDTable(updateDTable+1)
    }
    ///Edit Popup Function
    const ShowEditPopup = (props) => {
        if (props.lid) {
            if (userdata.user_type == 1) {
                //alert("Admin not allowed to update leads.");
                //return
            }
            return <UpdateLead
                lid={props.lid}
                compo={props.compo}
                ResetLid={ResetLid}
                agents={agents}
            />;
        }
        return;
    }

    const ClickEditPopup = (props) => {
        if (lid === props) {
            alert("You have recently closed this lead, Try again.")
            setLid(0)
            return
        }
        setLid(props)
    }

    const resetPage = (event) => {
        console.log(event)
        console.log("resetPage")
        window.location.reload();
    }
    useEffect(() => {
        const fetchTableData = async () => {
            const fbody = new FormData();
            fbody.append("action", "user_list");
            fbody.append("user_type", 2);
            await axios({
                method: "POST",
                url: CommonVariables.ApiUrl,
                data: fbody,
                headers: { 'Authorization': `Bearer ${token}` },
            })
                .then(function (apiresponse) {
                    AuthKeyCheck(apiresponse.data.urefid)
                    setAgents(apiresponse.data.data)
                    // console.log("LINE108");
                })
                .catch(function (apierror) {
                    console.log(apierror);
                });
        };
        fetchTableData();
    }, []
    )
    useEffect(() => {
        $('#tblfreshleads').DataTable({
            "searching": true,
            search: {
                "search": searchValue
            },
            order: [],
            columnDefs: [{
                'targets': [0, 7, 8], /* column index [0,1,2,3]*/
                'orderable': false, /* true or false */
            }],
            lengthMenu: [
                [100, 250, 500, 1000, 5000],
                [100, 250, 500, 1000, 5000],
            ],
            processing: true,
            serverSide: true,
            ajax: {
                url: CommonVariables.ApiUrl,
                type: "POST",
                data: function (d) {
                    d.action = "lead_list";
                    d.actiontype = "all";
                    var searchValue = d.search.value;
                    setSearchValue(searchValue);
                },
                beforeSend: function (request) {
                    // Fix: Add a space after 'Bearer'
                    request.setRequestHeader('Authorization', 'Bearer ' + token);
                }
            },
            success: function (apiresponse) {
                AuthKeyCheck(apiresponse.urefid)
            },
            columns: [
                {
                    "data": 'id',
                    render: function (data, type, row) {
                        return '<input name="chckbx" type="checkbox" className="custom-control-input" id=customCheck' + row['id'] + ' value=' + row['id'] + ' />';
                    }
                },
                {
                    data: 'refid',
                    render: function (data, type, row) {
                        return '<p class="prevent-select">' + row['refid'] + '</p>';
                    }

                },
                {
                    data: 'cdate',
                    render: function (data, type, row) {
                        return '<p class="prevent-select">' + row['cdate'] + '</p>';
                    }

                },

                {
                    data: 'business_name',
                    render: function (data, type, row) {
                        return '<p class="prevent-select">' + row['business_name'] + '</p>';
                    }
                },
                {
                    data: 'address',
                    render: function (data, type, row) {
                        return '<p class="prevent-select">' + row['address'] + '</p>'
                    }
                },
                {
                    data: 'number',
                    render: function (data, type, row) {
                        return `<a type="button" class="prevent-select" data-id=${row['id']}  href="tel: ${row['number']}" title="Show Log">${row['number']}</a>`;
                    }
                },
                {
                    data: 'number',
                    render: function (data, type, row) {
                        return `<span data-toggle="modal" data-target="#modal_detail" data-backdrop="static" data-keyboard="false"  class="cursor-pointer" data-id= ${row['number']} title="Show Log"><i class="fa fa-eye"></i> Log</span>`
                    }
                }, 
                {
                    data: 'fttpsogea',
                    render: function (data, type, row) {
                        return '<p class="prevent-select">' + row['fttpsogea'] + '</p>'
                    }
                },{
                    data: 'bb_type',
                    render: function (data, type, row) {
                        return '<p class="prevent-select">' + row['bb_type'] + '</p>'
                    }
                },
                {
                    "data": 'status_txt',
                    "render": function (data, t, row) {
                        var $select = $("<select  data-id='" + row['id'] + "'  class='status_select btn btn-xs btn btn-" + row['status_color'] + "'></select>", {
                            "id": row[0] + "start",
                            "value": data
                        });
                        $.each(CommonVariables.lead_status, function (k, v) {
                            var $option = $("<option></option>", {
                                "text": v.text,
                                "value": v.value
                            });
                            if (data === v.text) {
                                $option.attr("selected", "selected")
                            }
                            $select.append($option);
                        });
                        //   $select.children('option[value="0"]').remove();
                        return $select.prop("outerHTML");
                    }
                },
                // {
                //     "data": 'draw',
                //     render: function (data, type, row) {
                //         return '<button data-id="' + row['id'] + '" type="button" class="btn btn-' + row['status_color'] + '">' + row['status_txt'] + '</button>';
                //     }
                // },
                {
                    "data": 'draw',
                    render: function (data, type, row) {
                        return '<button data-id="' + row['id'] + '" type="button" class="btn btn-secondary">Enter</button>';
                    }
                }
            ]
        });

        $('#tblfreshleads tbody').on('click', 'button', function () {
            var clid = $(this).attr('data-id')
            ClickEditPopup(clid)
        });
        $('#tblfreshleads tbody').on('click', 'span', function () {
            var clid = $(this).attr('data-id')
            console.log("logid", clid);
            setlogid(clid)
            return;
        });
        $('#tblfreshleads tbody').on('change', 'select', function () {
            var clid = $(this).attr('data-id')
            var dataval = $(this).val()
            var prams = { "clid": clid, "dataval": dataval }
            UpdateLeadStatus(prams)
        });
    }, [updateDTable])

    const UpdateLeadStatus = (props) => {
        const updateData = async (props) => {
            const fbody = new FormData();
            fbody.append("lead_id", props.clid);
            fbody.append("cstatus", props.dataval);
            fbody.append("action", "lead_update");
            await axios({
                method: "POST",
                url: CommonVariables.ApiUrl,
                data: fbody,
                headers: { 'Authorization': `Bearer ${token}` }
            })
                .then(function (apiresponse) {
                    AuthKeyCheck(apiresponse.data.urefid)
                })
                .catch(function (apierror) {
                    console.log(apierror)
                });
        }
        updateData(props
        )
    }
    const ShowLogPopup = (props) => {
        // console.log("props",props);
        if (props.logid > 1) {
            return <LogList
                lnumber={props.logid}
                ResetLogPopup={ResetLogPopup}
                LogList={"/allleadsstatus"}
            />;
        }
    }
    const ResetLogPopup = () => {
        setlogid(0);
        $('.modal-backdrop').remove();
        $('body').removeClass('modal-open');
    }
    return (
        <>

            <ShowLogPopup logid={logid} />
            <div className="container-fluid p-0" style={{ overflow: 'hidden' }}>
                <SideMenu allleadsstatus="active" />
                <div className="body_">
                    <TopBand
                        pname="All Leads"
                    />
                    <div className="leads">
                        <div className="darkbg">
                            <div className="bg-white padding10 table_button_fix">
                               
                                <ShowEditPopup
                                    lid={lid}
                                    compo='/allleadsstatus'
                                />

                                <div className="table-responsive">
                                    <div className='resetbutton'>
                                <div className="button-header ">
                                    <button onClick={e => resetPage(e)} type="button" className="btn btn-success"><i className="fa fa-refresh"></i></button>
                                </div></div>
                                    <table id="tblfreshleads" className="table" style={{ width: '100%' }}>
                                        <thead>
                                            <tr>
                                                <th><div className="custom-control custom-checkbox">
                                                    <input
                                                        type="checkbox" className="custom-control-input" id="customCheck1"
                                                        onClick={selectAllCheckboxes}
                                                    />
                                                    <label className="custom-control-label" htmlFor="customCheck1"></label>
                                                </div></th>
                                                <th>ID</th>
                                                <th>Date & Time</th>
                                                <th width="15%">Name</th>
                                                <th width="15%">Address</th>
                                                <th>Number</th>
                                                <th>Log</th>
                                                <th>SOGEA/FTTP</th>
                                                <th>BB Type</th>
                                                <th>Status</th>
                                                <th>Notes</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default FreshLeads;