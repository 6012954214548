import React, { useState, useEffect } from 'react'
import axios from 'axios'
import CommonVariables from './CommonVariables'
import { Link } from 'react-router-dom'
import { Input } from "antd";
import { useSelector } from 'react-redux';
import { setval } from '../redux/common/commonSlice';
import AuthCheck from './AuthCheck';
import { AuthKeyCheck } from './Helpers';
// const userdata = useSelector((state) => state.data.entities)

const UpdateLead = (props) => {
    const [compo, setcompo] = useState(props.compo)
    const [lid, setlid] = useState(props.lid)
    const [assignee, setAssignee] = useState('')
    const [newassignee, setNewAssignee] = useState('')
    const [title, setTitle] = useState('')
    const [customername, setCustomername] = useState('')
    const [number, setNumber] = useState('')
    const [fttp, setFttp] = useState('')
    const [sogea, setSogea] = useState('')
    const [email, setEmail] = useState('')
    const [mobilenumber, setMobilenumber] = useState('')
    const [postcode, setPostcode] = useState('')
    const [broadband, setBroadband] = useState('')
    const [address, setAddress] = useState('')
    const [additional, setAdditional] = useState('')
    const [amount, setAmount] = useState('')
    const [businessname, setBusinessname] = useState('')
    const [currentprovider, setCurrentprovider] = useState('')
    const [leadstatus, setLeadstatus] = useState('')
    const [notes, setNotes] = useState('')
    const [cdate, setCdate] = useState('')
    const [cbtime, setCbtime] = useState('')
    const [utype, setUtype] = useState('')
    const [oldassignee, setOldassignee] = useState('')
    const [oldassignee_name, setOldassignee_name] = useState('')
    const [agents, setAgents] = useState(props.agents)
    const userData = useSelector((state) => state.data.entities)
    console.log("userdata", userData.session_key);
    const [token, setToken] = useState(userData.session_key);
    useEffect(() => {
        setToken(userData.session_key)
    }, [userData])
    useEffect(() => {
        if (lid > 0) {
            const fetchTableData = async () => {
                let formData = new FormData();
                formData.append('action', 'lead_detail')
                formData.append('lead_id', lid)
                axios({
                    method: 'post',
                    url: CommonVariables.ApiUrl,
                    data: formData,
                    headers: { 'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${token}` }
                })
                    .then(function (response) {
                        //handle success
                        AuthKeyCheck(response.data.urefid)
                        if (response.data.rescode === 1) {
                            setAssignee(response.data.data.assignee)
                            setTitle(response.data.data.title)
                            setCustomername(response.data.data.name)
                            setNumber(response.data.data.number)
                            setFttp(response.data.data.fttp)
                            setSogea(response.data.data.sogea)
                            setEmail(response.data.data.email)
                            setMobilenumber(response.data.data.phone)
                            setPostcode(response.data.data.postcode)
                            setBroadband(response.data.data.bb_type)
                            setAddress(response.data.data.address)
                            setAdditional(response.data.data.add_cli)
                            setAmount(response.data.data.deal_amount)
                            setLeadstatus(response.data.data.cstatus)
                            setBusinessname(response.data.data.business_name)
                            setCurrentprovider(response.data.data.current_provider)
                            setNotes(response.data.data.notes)
                            setCdate(response.data.data.callback_date)
                            setCbtime(response.data.data.callback_time)
                            setOldassignee(response.data.data.oldassignee)
                            setOldassignee_name(response.data.data.oldassignee_name)
                            // })
                        } else {
                            console.log();
                            alert(response.data.resmsg);
                        }
                    })
                    .catch(function (response) {
                        console.log(response)
                    });
            }
            if (agents.length === 0) {
                const fbody = new FormData();
                fbody.append("action", "user_list");
                fbody.append("user_type", 2);
                axios({
                    method: 'post',
                    url: CommonVariables.ApiUrl,
                    data: fbody,
                    headers: { 'Authorization': `Bearer ${token}` }
                })
                    .then(function (response) {
                        AuthKeyCheck(response.data.urefid)
                        setAgents(response.data.data)
                    })
                    .catch(function (response) {
                        console.log(response)
                    });
            }
            fetchTableData()
        }
    }, [lid])
    const handleFormSubmit = (event) => {
        event.preventDefault();
        // const dis = this;
        let addleadformdata = document.getElementById("leadform")
        let formData = new FormData(addleadformdata);
        formData.delete('lid');
        formData.append('action', 'lead_update')
        formData.append('lead_id', lid)
        axios({
            method: 'post',
            url: CommonVariables.ApiUrl,
            data: formData,
            headers: { 'Content-Type': 'multipart/form-data', 'Authorization': `Bearer ${token}` }
        })
            .then(function (response) {
                AuthKeyCheck(response.data.urefid)
                if (response.data.rescode === 1) {
                    // localStorage.setItem("userdata", JSON.stringify(response.data.data));
                    // window.location.href = "/";
                    alert(response.data.resmsg);
                    setlid(0)
                    props.ResetLid()
                } else {
                    alert(response.data.resmsg);
                }
            })
            .catch(function (response) {
                //handle error
                console.log(response)
            });
    }
    // const  handleChange =(event) =>{
    //    setval({ value: event.target.value });
    // }
    return (
        <div
            onPaste={(e) => {
                e.preventDefault()
                return false;
            }} onCopy={(e) => {
                e.preventDefault()
                return false;
            }}
        >
            <React.Fragment>
                <div className={"modal fade " + (lid > 0 ? 'show d-block' : '')} id="modal_detail">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-body" style={{ height: '100vh', overflow: 'auto' }}>
                                <div className="form-row">
                                    <div className="container-fluid p-0" style={{ overflow: 'hidden' }}>
                                        <div className="p-0 leads coustomerlead">
                                            <div className="row">
                                                <div className="col-xl-11 col-11">
                                                    <h4 className="mb-0 prevent-select">Lead Details {oldassignee > 0 ? ' - Lead Sent By ' + oldassignee_name : ''}
                                                    </h4>
                                                </div>
                                                <div className="col-xl-1 col-1">
                                                    <Link className="float-right" to={compo}><i className="fa fa-close" onClick={e => {
                                                        setlid(0)
                                                        props.ResetLid()
                                                    }}></i></Link>
                                                </div>
                                            </div>
                                            <div className="bg-white padding10 p-0 mt-3">
                                                <form id="leadform" name="leadform">
                                                    <div className="row">
                                                        <div className="col-md-6 col-12">
                                                            <div className="form-group">
                                                                <label htmlFor="lid">ID</label>
                                                                <input

                                                                    type="number"
                                                                    className="form-control"
                                                                    id="lid"
                                                                    name='lid'
                                                                    defaultValue={lid}
                                                                    onChange={e => setlid(e.target.value)}
                                                                    readOnly={true}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6 col-12 d-none">
                                                            <div className="form-group">
                                                                <label htmlFor="assignee">Assignee</label>
                                                                <input type="name" className="form-control" id="assignee" name='assignee' defaultValue={assignee}
                                                                    onChange={e => setAssignee(e.target.value)}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-xl-2 col-3">
                                                            <div className="form-group">
                                                                <label htmlFor="title">Title</label>
                                                                <input type="name" className="form-control" id="title" name='title'
                                                                    defaultValue={title}
                                                                    onChange={e => setTitle(e.target.value)}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-xl-4 col-9">
                                                            <div className="form-group">
                                                                <label htmlFor="customername">Customer Name</label>
                                                                <input type="name" className="form-control disabled-input" id="customername" name='name'
                                                                    onChange={e => setCustomername(e.target.value)}
                                                                    defaultValue={customername}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-xl-6 col-12">
                                                            <div className="form-group">
                                                                <label htmlFor="number">Number</label>
                                                                <input type="text" className="form-control" id="number" name='number'
                                                                    defaultValue={number}
                                                                    onChange={e => setNumber(e.target.value)}
                                                                />
                                                            </div>
                                                            <div className="form-group">
                                                                <label htmlFor="email">Email Address</label>
                                                                <input type="email" className="form-control" id="email" name='email'
                                                                    defaultValue={email}
                                                                    onChange={e => setEmail(e.target.value)}
                                                                />
                                                            </div>
                                                            <div className="form-group">
                                                                <label htmlFor="mobilenumber">Mobile Number</label>
                                                                <input type="text" className="form-control" id="mobilenumber" name='phone'
                                                                    defaultValue={mobilenumber}
                                                                    onChange={e => setMobilenumber(e.target.value)}
                                                                />
                                                            </div>

                                                            <div className="form-group">
                                                                <label htmlFor="postcode">Tag Postcode</label>
                                                                <input type="text" className="form-control" id="postcode" name='postcode'
                                                                    defaultValue={postcode}
                                                                    onChange={e => setPostcode(e.target.value)}
                                                                />
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <div className="form-group">
                                                                        <label htmlFor="broadband">Broadband Type</label>
                                                                        <input type="text" className="form-control" id="broadband" name='bb_type'
                                                                            defaultValue={broadband}
                                                                            onChange={e => setBroadband(e.target.value)}
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-3">
                                                                    <div className="form-group">
                                                                        <label htmlFor="fttp">FTTP</label>
                                                                        <input type="text" className="form-control" id="fttp" name='fttp'
                                                                            defaultValue={fttp}
                                                                            onChange={e => setFttp(e.target.value)}
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div className="col-md-3">
                                                                    <div className="form-group">
                                                                        <label htmlFor="sogea">SOGEA</label>
                                                                        <input type="text" className="form-control" id="sogea" name='sogea'
                                                                            defaultValue={sogea}
                                                                            onChange={e => setSogea(e.target.value)}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="form-group">
                                                                <label htmlFor="address">Address</label>
                                                                <textarea name='address' onChange={e => setAddress(e.target.value)} className="form-control" id="address" rows="3" value={address}></textarea>
                                                            </div>

                                                            <div className="form-group ">
                                                                <label htmlFor="additional">Additional CLI's</label>
                                                                <textarea name='add_cli' onChange={e => setAdditional(e.target.value)} className="form-control" id="additional" rows="3" value={additional}></textarea>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6 col-12">



                                                            <div className="form-group">
                                                                <label htmlFor="amount">Amount</label>
                                                                <input type="text" className="form-control" id="amount" name='deal_amount' defaultValue={amount}
                                                                    onChange={e => setAmount(e.target.value)}
                                                                />
                                                            </div>

                                                            <div className="form-group">
                                                                <label htmlFor="leadstatus">Lead Status</label>
                                                                <select className="form-control" id="leadstatus" name='cstatus' value={leadstatus}
                                                                    onChange={(e) => setLeadstatus(e.target.value)}>
                                                                    {CommonVariables.lead_status.map(item => {
                                                                        return (<option key={item.value} value={item.value}>{item.text}</option>);
                                                                    })}
                                                                </select>
                                                            </div>

                                                            <div className="form-group"
                                                            >
                                                                <label htmlFor="businessname">Business Name</label>
                                                                <input
                                                                    // onkeydown="javascript:Disable_Control_C()"
                                                                    type="name" className="form-control" id="businessname" name='business_name'
                                                                    defaultValue={businessname}
                                                                    onChange={e => setBusinessname(e.target.value)}
                                                                    onselectstart="return false"
                                                                    style={{ userSelect: 'none' }}
                                                                />
                                                            </div>
                                                            <div className="form-group">
                                                                <label htmlFor="currentprovider">Current Provider</label>
                                                                <input type="text" className="form-control" id="currentprovider" name='current_provider'
                                                                    defaultValue={currentprovider}
                                                                    onChange={e => setCurrentprovider(e.target.value)}
                                                                />
                                                            </div>
                                                            <div className="form-group">
                                                                <label htmlFor="cdate">Call Back Date</label>
                                                                <input type="date" className="form-control" id="cdate" name='callback_date'
                                                                    value={cdate}
                                                                    onChange={e => setCdate(e.target.value)}
                                                                />
                                                            </div>
                                                            <div className="form-group">
                                                                <label htmlFor="cbtime">Call Back Time</label>
                                                                <input type="time" className="form-control" id="cbtime" name='callback_time' defaultValue={cbtime}
                                                                    onChange={e => setCbtime(e.target.value)}
                                                                />
                                                            </div>
                                                            <div className="form-group">
                                                                <label htmlFor="newassignee">Assign Lead to Other Agent</label>
                                                                <select
                                                                    className="form-control"
                                                                    id="newassignee"
                                                                    name='newassignee'
                                                                    value={newassignee}
                                                                    onChange={(e) => setNewAssignee(e.target.value)}>
                                                                    <option value={0}>Select a Agent</option>
                                                                    {agents.map(item => {
                                                                        return (<option key={item.id} value={item.id}>{item.name}</option>);
                                                                    })}
                                                                </select>
                                                            </div>
                                                            <div className="form-group">
                                                                <label htmlFor="notes">Notes</label>
                                                                <textarea name='notes' className="form-control" id="notes" rows="3" onChange={e => setNotes(e.target.value)} value={notes}> </textarea>
                                                            </div>


                                                            <div className='button-header text-right mt-4 prevent-select'>
                                                                <button type="submit" onClick={e => handleFormSubmit(e)} className="btn btn-success mb-2 prevent-select">SAVE</button>
                                                            </div>{/* <input type="submit" className="btn btn-secondary float-right" value="SAVE"/> */}
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </React.Fragment >

        </div>
    )
}
export default UpdateLead;