import axios from 'axios';
import React, { createContext, useContext, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { setudata } from '../redux/common/commonSlice';
import CommonVariables from './CommonVariables';
const AuthCheck = (props) => {
  const dispatch = useDispatch()
  function getsessionStorageValue(key, defaultValue) {
    // getting stored value
    if (typeof window != "undefined") {
      const saved = sessionStorage.getItem(key);
      const initial = saved != null ? JSON.parse(saved) : defaultValue;
      return initial;
    }
  }
  const userdata = getsessionStorageValue("userdata", "defaultValue");
  if (userdata.id == "" || userdata.id == "undefined" || userdata == "defaultValue") {
    window.location.href = "/login";
    console.log("Not loggedin");
  }
  //console.log("userdata");
  //console.log(userdata);
  //console.log("Linenumber21");
  //console.log(userdata.id);
  //console.log(userdata.user_type);

  useEffect(() => {
    dispatch(setudata(userdata))
  }, [])
  useEffect(() => {
    const interval = setInterval(() => {
      console.log('This will be called every 30 seconds');
      importCsvData()
    }, 30000);
    return () => clearInterval(interval);
  }, []);
  const importCsvData = async () => {
    await axios({
      method: "POST",
      url: CommonVariables.ApiBase + 'sync-imported-data.php'
    })
      .then(function (apiresponse) {
      })
      .catch(function (apierror) {
        console.log(apierror)
      });
  }

  const UserContext = createContext(userdata);
  return (
    <div>

    </div>
  )
}
export default AuthCheck;